import React, { ReactNode } from 'react';
import { FormHelperText, Box, Checkbox, FormControlLabel, FormControl, Link } from '@mui/material';

import { useContact } from '../../contexts/contactContext/contactFormContext';
import { CheckboxField } from '../../contexts/contactContext/contact';

interface CheckboxViewProps {
  field: CheckboxField;
  customLabel?: ReactNode;
}

export const CheckboxView = ({
  field: { id, tip, value, label, checked, hidden, onChange, required, defaultValue },
  customLabel,
}: CheckboxViewProps) => {
  const {
    form: { formState, setValue, register, getValues },
  } = useContact();

  const error = Boolean(formState.errors.contact?.[id]);
  const helperText = formState.errors.contact?.[id]?.message;

  if (hidden) return null;

  return (
    <Box>
      <FormControl error={error}>
        <FormControlLabel
          control={
            <Checkbox
              checked={getValues(`contact.${id}`) === true || defaultValue === true}
              color="secondary"
              onChange={(e) => {
                const value = e.target.checked ? true : false;
                // React form lib operates with default string values 'false' and 'true' that brakes our use-case
                setValue(`contact.${id}`, value, {
                  shouldValidate: true,
                });
                // react hook form use classes internally and override 'this'
                // so we need to bind the onChange function to the original 'this'
                onChange?.onChange.bind(onChange)(value);
              }}
            />
          }
          required={required}
          label={customLabel ? customLabel : label}
          {...register(`contact.${id}`)}
        />
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    </Box>
  );
};
