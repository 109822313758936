import { Button, Collapse } from '@mui/material';
import { Text } from '@eo-locale/react';
import { styled } from '@guest-widgets/core';
import { booking } from '@guest-widgets/shared/src/classes/booking';
import { ICON_BASE_SIZE, LoadingButton } from '@guest-widgets/shared/src/components/LoadingButton';
import { useNumberFormatter } from '@guest-widgets/shared/src/hooks/useNumberFormatter';

import { NavigationContainer, StyledErrorContainer } from '../../StyledSharedComponents';
import { ApiInvalidStructureException } from '../../contexts/apiContext/apiContext';
import { ErrorMessage } from '../../Error';
import { useUpsellInSelection } from '../upsellInSelectionContext';
import { useInnerUpsell } from '../../hooks/upsell/useInnerUpsell';

import { useInnerDrawer } from './useInnerDrawer';

export interface NavigationProps {
  parentErrors?: string[];
  disabled: boolean;
  rateLimitError?: boolean;
  showRemoveButton: boolean;
  onAddToCart: () => void;
  onRemoveFromCart: () => void;
}

export const Navigation = ({
  disabled,
  parentErrors,
  rateLimitError,
  showRemoveButton,
  onAddToCart,
  onRemoveFromCart,
}: NavigationProps) => {
  const {
    upsellInModal: { companyInformation, currency, totalQuantity },
  } = useUpsellInSelection();
  const { isLoading } = useInnerUpsell();
  const { upsellApi } = useInnerDrawer();
  const { formatPrice } = useNumberFormatter(companyInformation?.locale?.language);

  const { data, error } = upsellApi;
  const { error: pricingError, totals } = data || {};
  const { subtotal } = totals || {};
  const hasError = !!error || !!pricingError;
  const totalPrice =
    subtotal && totalQuantity && !hasError ? formatPrice(subtotal.amount, currency) : '';

  const renderErrors = () => {
    if (rateLimitError)
      return <ErrorMessage show messageKey={'rate-limited-error'} mt={0} mb={0} />;
    if (parentErrors?.length)
      return parentErrors.map((error) => (
        <ErrorMessage key={error} show={!!error} message={error} mt={0} mb={0} />
      ));
    if (upsellApi.error instanceof ApiInvalidStructureException)
      return <ErrorMessage show={upsellApi.isError} message={upsellApi.error.message} mt={0} />;
    return <ErrorMessage show={upsellApi.isError} messageKey="server-unreachable" mt={0} />;
  };

  return (
    <FloatingButtons className={booking.navigation.root}>
      <LoadingButton
        loading={upsellApi.isFetching || isLoading}
        variant="contained"
        color="primary"
        size="large"
        iconSize={ICON_BASE_SIZE}
        fullWidth
        onClick={onAddToCart}
        disabled={disabled || hasError}
        className={`${booking.buttons.button} ${booking.buttons.upsell}`}
      >
        <Text id="add-to-cart" />
        {totalPrice ? ` • ${totalPrice}` : ''}
      </LoadingButton>
      <StyledErrorContainer>{renderErrors()}</StyledErrorContainer>
      <Collapse in={showRemoveButton} unmountOnExit>
        <Button
          variant="outlined"
          color="error"
          onClick={onRemoveFromCart}
          fullWidth
          className={booking.buttons.back}
        >
          <Text id="remove-from-cart" />
        </Button>
      </Collapse>
    </FloatingButtons>
  );
};

const FloatingButtons = styled(NavigationContainer)({
  position: 'sticky',
  bottom: 0,
  left: 0,
  right: 0,
  gap: 8,
  background: 'linear-gradient(transparent 25%, #FFFFFF 60%)',
  zIndex: 1,
});
