import { useState } from 'react';
import { Link, Typography, styled } from '@mui/material';
import { Text } from '@eo-locale/react';

import { BuyerAdditionalField, CheckboxField } from '../contexts/contactContext/contact';
import { useContact } from '../contexts/contactContext/contactFormContext';

import { CheckboxView } from './Fields/CheckboxView';
import TosDialog from './TosDialog';

type Props = {
  customerTosField: BuyerAdditionalField;
};

const TOService = ({ customerTosField }: Props) => {
  const [openPolicyDialog, setOpenPolicyDialog] = useState(false);
  const {
    form: { setValue },
  } = useContact();
  const openPolicyAgreementDialog = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    setOpenPolicyDialog(true);
  };

  const closePolicyAgreementDialog = () => {
    setOpenPolicyDialog(false);
  };

  const onAcceptPolicy = () => {
    if (customerTosField && customerTosField.length > 1) {
      setValue('contact.customer_policy_agree', true, {
        shouldValidate: true,
      });
    }
    closePolicyAgreementDialog();
  };

  if (!customerTosField || customerTosField.length == 0) return <></>;
  const Label = (
    <>
      {customerTosField.length === 1 ? (
        <Acknowledge>
          <Text id="clicking-next-acknowledge" />
        </Acknowledge>
      ) : (
        <Text id="read-consent" />
      )}
      <Link
        sx={{ textDecoration: 'underline', display: 'inline' }}
        onClick={openPolicyAgreementDialog}
      >
        {' '}
        <Text id="customer-policy-agree" />
      </Link>
    </>
  );

  return (
    <TosWrapper>
      {customerTosField.length === 1 ? (
        Label
      ) : (
        <CheckboxView field={customerTosField[1] as CheckboxField} customLabel={Label} />
      )}
      <TosDialog
        open={openPolicyDialog}
        onClose={closePolicyAgreementDialog}
        policyContent={customerTosField[0]?.value as string}
        onAcceptPolicy={onAcceptPolicy}
      />
    </TosWrapper>
  );
};

const TosWrapper = styled('div')(({ theme: { spacing } }) => ({
  marginTop: spacing(4),
  marginBottom: spacing(4),
}));
const Acknowledge = styled(Typography)(({ theme: { spacing } }) => ({
  marginTop: spacing(4),
  fontSize: 14,
  lineHeight: spacing(6),
  display: 'inline',
}));

export default TOService;
