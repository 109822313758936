import { DateRestriction, UpsellItem } from '../../upsellContext/upsell';
import { LineItem, Upsell } from '../product/dtos/response';
import { removeTimezone } from '../product/formatDate';
import { mapToProduct } from '../product/mapToProduct';
import { SupportedCurrencies } from '../../settingsContext/settings';

export const mapToUpsells = (lineItem: LineItem): UpsellItem[] => {
  const { upsells } = lineItem;

  if (!upsells) return [];

  const filteredUpsells =
    import.meta.env.VITE_RESTRICT_UPSELL_CASES === 'true'
      ? upsells.filter(
          ({
            configuration: { dateRestriction, attachmentType, restrictGuestTypeAdjustment },
            product: { guestTypes },
          }) =>
            attachmentType === 'optional' &&
            !restrictGuestTypeAdjustment &&
            ((dateRestriction === DateRestriction.PARENT_DATES &&
              (!guestTypes || guestTypes.length < 2)) ||
              dateRestriction === DateRestriction.UNRESTRICTED)
        )
      : upsells;

  return filteredUpsells.map((upsell) => {
    const product = mapToProduct(
      upsell.price.gross.currencyCode as SupportedCurrencies,
      upsell.product
    );
    return {
      ...product,
      price: Number(upsell.price.gross.amount),
      parent: mapToParent(lineItem, upsell),
      configuration: mapToConfiguration(upsell),
      totalQuantity: 0,
    };
  });
};

const mapToParent = (lineItem: LineItem, upsell: Upsell) => ({
  productId: upsell.configuration.productId.toString(),
  lineId: lineItem.lineId,
  start: removeTimezone(lineItem.start),
  end: removeTimezone(lineItem.end),
});

const mapToConfiguration = (upsell: Upsell) => ({
  attachmentType: upsell.configuration.attachmentType,
  dateRestriction: upsell.configuration.dateRestriction as DateRestriction,
  sameParentQuantity: upsell.configuration.restrictGuestTypeAdjustment,
  allowTimeAdjustment: upsell.configuration.allowTimeAdjustment,
});
