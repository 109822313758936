import { useEffect } from 'react';
import { handleTrackingEvent } from '@guest-widgets/shared/src/utils/customerTracking/utils/trackingEventsHandler';
import { TrackingEventType } from '@guest-widgets/shared/src/utils/customerTracking/types';

import { CartContext, useCart } from '../contexts/cartContext/cartContext';
import { Step } from '../contexts/routerContext/router';

import { virtualPageMapper } from './mappers';

/** Hook to get information from tracked events (e.g. add_to_cart, remove_from_cart...)
 * and send them to the analytics tools */
export const useTrackingInfo = (currentStep: Step) => {
  const { cartWithSteps } = useCart();
  const { cart } = cartWithSteps.data || {};

  useEffect(() => {
    if (!cart || !currentStep) return;

    if (['product', 'cart', 'contact', 'checkout', 'upsell'].includes(currentStep)) {
      const virtualPageInfo =
        virtualPageMapper[currentStep as Exclude<Step, 'purchaseFailure' | 'purchaseSuccess'>];
      if (virtualPageInfo) {
        handleTrackingEvent(TrackingEventType.PAGE_VIEW, { virtualPageViewData: virtualPageInfo });
      }
    }
  }, [currentStep]);
};
