import { InputAdornment, MenuItem, TextField as MuiTextField } from '@mui/material';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import { mapMomentToDateFormat } from '@guest-widgets/shared/src/mappers/momentToDateFnsMapper';
import { useCalendarLanguage } from '@guest-widgets/shared/src/components/Calendar/useCalendarLanguage';
import { styled } from '@guest-widgets/core';
import { addMinutes, format, isAfter, isSameDay, subMinutes } from 'date-fns';
import { blue } from '@mui/material/colors';

import { useSettings } from '../../../contexts/settingsContext/settingsContext';
import { TimeRange, useProductInSelection } from '../../Guest/productInSelectionContext';
import type { AvailabilityTimeDayTicket } from '../../../contexts/timeslotsContext/timeslots';
import { TimeslotDayRange } from '../Day/Calendar/CalendarRange/useCalendarRange';

interface SelectFlextimeProps {
  timeChange: (time: Date) => void;
  availabilities: Array<AvailabilityTimeDayTicket>;
  timeSelected?: Partial<TimeRange>;
  period: 'startTime' | 'endTime';
}

interface GetDisabledProps {
  period: 'startTime' | 'endTime';
  optionRange: TimeRange;
  startTime?: Date;
  minDuration?: number;
  interval?: number;
  availabilities: Array<AvailabilityTimeDayTicket>;
  date?: TimeslotDayRange;
}

export const SelectFlextime = ({
  timeChange,
  availabilities,
  timeSelected,
  period,
}: SelectFlextimeProps) => {
  const { companyInformation, date, interval } = useProductInSelection();
  const { locale } = useSettings();
  const calendarLanguage = useCalendarLanguage(locale);
  const timeDate = timeSelected?.[period]?.getTime();

  return (
    <TextField
      select
      fullWidth
      value={timeDate ?? ''}
      onChange={(event) => timeChange(new Date(event.target.value))}
      InputProps={{
        startAdornment: (
          <Adornment position="start">
            <WatchLaterIcon />
          </Adornment>
        ),
      }}
    >
      {availabilities.map((option) => (
        <Option
          key={option.timeRange[period].getTime()}
          value={option.timeRange[period].getTime()}
          disabled={getDisabled({
            period,
            startTime: timeSelected?.startTime,
            optionRange: option.timeRange,
            minDuration: option.rules?.minDuration,
            interval,
            availabilities,
            date,
          })}
        >
          {format(
            option.timeRange[period],
            mapMomentToDateFormat(companyInformation?.locale?.timeFormatMoment!),
            {
              locale: calendarLanguage,
            }
          )}
        </Option>
      ))}
    </TextField>
  );
};

const getDisabled = ({
  period,
  startTime,
  optionRange,
  minDuration = 0,
  interval = 60,
  availabilities,
  date,
}: GetDisabledProps) => {
  const { from, to } = date ?? {};
  const oneDayRange = from?.day && to?.day && isSameDay(from.day, to.day);

  if (period === 'startTime') {
    const { endTime } = availabilities[availabilities.length - 1].timeRange ?? {};
    const lastPossibleStartTime = subMinutes(endTime, minDuration * interval);
    return oneDayRange && optionRange.startTime
      ? isAfter(optionRange.startTime, lastPossibleStartTime)
      : true;
  }

  const comparedStartTime =
    oneDayRange && minDuration
      ? addMinutes(startTime!, minDuration * interval - interval)
      : startTime;
  return !!comparedStartTime && optionRange.endTime
    ? !isAfter(optionRange.endTime, comparedStartTime)
    : true;
};

const TextField = styled(MuiTextField)(({ theme: { spacing } }) => ({
  minWidth: 148,
  flexBasis: 148,
  flexGrow: 1,
  '& .MuiInputBase-root': {
    paddingLeft: spacing(3),
    height: 44,
    '& [role="combobox"]': {
      padding: 0,
    },
  },
}));

const Adornment = styled(InputAdornment)({
  '& svg': {
    color: '#6E6E6E',
  },
});

const Option = styled(MenuItem)(({ theme }) => ({
  borderRadius: theme.spacing(1),
}));
