import { Typography } from '@mui/material';
import { useMemo } from 'react';
import { useNumberFormatter } from '@guest-widgets/shared/src/hooks/useNumberFormatter';
import { styled } from '@guest-widgets/core';

import { CartItem } from '../contexts/apiContext/cart/cart';
import { useProduct } from '../contexts/productContext/productContext';

interface ItemPriceProps {
  item: CartItem;
}

export const SoldItemPrice = ({ item }: ItemPriceProps) => {
  const { companyInformation } = useProduct();
  const { formatPrice } = useNumberFormatter(companyInformation?.locale?.language);
  const { price, discount } = item;
  const itemPrice = formatPrice(price.total.amount, price.total.currencyCode);

  const getDiscountData = useMemo(() => {
    if (discount?.adjustmentType === 'percent' && discount.amount) {
      return `-${discount.amount * 100}%`;
    }
    if (discount?.adjustmentType === 'fixed' && discount.amount) {
      return `-${formatPrice(discount.amount, price.total.currencyCode)}`;
    }
  }, [price.total.currencyCode, discount?.adjustmentType, discount?.amount]);

  return (
    <>
      {discount ? (
        <DiscountWrapper>
          <DiscountText variant="h6">{getDiscountData}</DiscountText>
          <Price variant="body2">{itemPrice}</Price>
        </DiscountWrapper>
      ) : (
        <Price variant="body2">{itemPrice}</Price>
      )}
    </>
  );
};

const DiscountWrapper = styled('span')({
  display: 'flex',
  flexDirection: 'column',
});

const DiscountText = styled(Typography)(({ theme: { spacing } }) => ({
  alignSelf: 'end',
  lineHeight: 1,
  fontWeight: 500,
  backgroundColor: '#64B473',
  borderRadius: spacing(2),
  padding: spacing(0.5, 1),
  marginTop: spacing(1),
}));

const Price = styled(Typography)(({ theme: { spacing } }) => ({
  marginTop: spacing(1),
  marginLeft: spacing(4),
  opacity: 0.7,
}));
