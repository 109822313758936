import React, { ChangeEvent, useEffect, useState } from 'react';
import { Typography, Divider } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useDebouncedValue } from '@guest-widgets/shared/src/hooks/useDebouncedValue';
import { useTranslator } from '@eo-locale/react';

import { classes } from '../../classes';

import {
  ComponentPosition,
  Container,
  Controller,
  CustomButtonGroup,
  IconButton,
  TextField,
  Tooltip,
} from './styles';

export interface QuantitySelectorProps {
  quantity: number;
  label?: string;
  labelClassName?: string;
  className?: string;
  /** Can be 'start' or 'end' where the whole component will be on left or right.
   * It considers label property and properly error messages alignment.
   * The default value is 'end'.
   */
  position?: ComponentPosition;
  size?: 'large' | 'medium' | 'small';
  max?: number;
  min?: number;
  /** Used to force error when quantity can not be zero. The default value is false. */
  required?: boolean;
  errorMessages?: string[];
  handleErrorStatus?: (errors: string[]) => void;
  handleQuantityChange: (quantity: number) => void;
}

export const QuantitySelector = ({
  quantity,
  label,
  labelClassName,
  className,
  position = 'end',
  size = 'small',
  max = Infinity,
  required = false,
  min = 0,
  errorMessages = [],
  handleQuantityChange,
  handleErrorStatus,
}: QuantitySelectorProps) => {
  const [localQuantity, setLocalQuantity] = useState(quantity);
  const [localErrorMessages, setLocalErrorMessages] = useState(errorMessages);
  const debouncedLocalState = useDebouncedValue(localQuantity, DEBOUNCE_TIME);
  const { translate } = useTranslator();

  const hasErrors = localErrorMessages.length > 0;
  const minAndMaxErrorMessage =
    max !== Infinity
      ? translate('enter-number-between-variable', {
          min,
          max,
        })
      : translate('enter-number-bigger-or-equal-variable', { min });

  const setErrors = (error: string[]) => {
    const uniqueErrors = error.filter((value, index, array) => array.indexOf(value) === index);
    setLocalErrorMessages(uniqueErrors);
    if (!isBetweenMinAndMax(quantity)) setLocalQuantity(quantity);
  };

  useEffect(() => {
    if (!errorMessages.length) return;
    setErrors(errorMessages);
  }, [errorMessages.join()]);

  useEffect(() => {
    if (localErrorMessages.length) {
      const timer = setTimeout(() => setErrors([]), EXIBITION_TIME);
      return () => clearTimeout(timer);
    }
  }, [localErrorMessages]);

  useEffect(() => {
    if (debouncedLocalState === undefined || debouncedLocalState === quantity) return;

    if (!isBetweenMinAndMax(debouncedLocalState)) {
      setErrors([...localErrorMessages, minAndMaxErrorMessage]);
      handleErrorStatus?.([...localErrorMessages, minAndMaxErrorMessage]);
      return;
    }

    handleQuantityChange(debouncedLocalState);
  }, [debouncedLocalState]);

  const isBetweenMinAndMax = (quantity: number) => {
    const hasMaxError = quantity > max;
    const hasMinError = (quantity !== 0 && quantity < min) || (quantity === 0 && required);
    return !hasMaxError && !hasMinError;
  };

  return (
    <Tooltip
      title={localErrorMessages.map((message) => (
        <p key={message}>{message}</p>
      ))}
      open={hasErrors}
      placement="top"
    >
      <Container className={className}>
        <Controller position={position}>
          {label && (
            <Typography color="inherit" variant="body1" className={labelClassName}>
              {label}:
            </Typography>
          )}
          <CustomButtonGroup>
            <IconButton
              size={size}
              onClick={() => {
                if (localQuantity === min && min !== 0 && !required) {
                  setLocalQuantity(0);
                } else {
                  setLocalQuantity(localQuantity - 1);
                }
              }}
              disabled={(localQuantity <= min && required) || (localQuantity === 0 && !required)}
              className={classes.booking.qty.minus}
              aria-label="minus"
            >
              <RemoveIcon fontSize={size} />
            </IconButton>
            <Divider orientation="vertical" flexItem />
            <TextField
              size={size}
              error={hasErrors}
              value={localQuantity}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setLocalQuantity(e.target.value ? parseInt(e.target.value) : 0)
              }
              type="number"
              inputProps={{ max, min, 'aria-label': 'quantity' }}
            />
            <Divider orientation="vertical" flexItem />
            <IconButton
              size={size}
              onClick={() => {
                if (localQuantity === 0 && min !== 0) {
                  setLocalQuantity(min);
                } else {
                  setLocalQuantity(localQuantity + 1);
                }
              }}
              disabled={localQuantity >= max}
              className={classes.booking.qty.plus}
              aria-label="plus"
            >
              <AddIcon fontSize={size} />
            </IconButton>
          </CustomButtonGroup>
        </Controller>
      </Container>
    </Tooltip>
  );
};

const EXIBITION_TIME = 5000;
const DEBOUNCE_TIME = 300;
