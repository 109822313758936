import { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { classes } from '@guest-widgets/shared/src/classes';
import { Text } from '@eo-locale/react';

import { useTotal, TotalRow as TotalRowType } from '../hooks/cart/useTotal';
import TotalRow from '../Cart/Items/TotalRow';
import { useRouter } from '../contexts/routerContext/routerContext';

export const Totals = () => {
  const [totals, setTotals] = useState<TotalRowType[]>([]);
  const { totalRows } = useTotal();
  const grandTotal = totalRows.find((row) => row.title === 'grand-total');

  // Set totals only when totalRows has values avoiding rendering blinking
  useEffect(() => {
    if (totalRows.length) {
      setTotals(totalRows);
    }
  }, [grandTotal]);

  const { currentStep } = useRouter();
  const isPurchaseSuccess = currentStep === 'purchaseSuccess';

  return (
    <div className={classes.booking.checkout.totals}>
      {!isPurchaseSuccess && (
        <Typography mb={4} color="inherit" variant="h4" mt={4}>
          <Text id="price-details" />
        </Typography>
      )}
      <Box className={classes.booking.totals.totals}>
        {totals
          .filter((totalRow) => totalRow?.render)
          .map((row) => {
            return row ? <TotalRow key={row.title} {...row} /> : null;
          })}
      </Box>
    </div>
  );
};
