import { useMemo } from 'react';

import { useCart } from '../../contexts/cartContext/cartContext';
import { DepositOption } from '../../contexts/apiContext/cart/cart';
import { PublicMoney } from '../../contexts/cartContext/cart';

interface UsePaymentResult {
  priceToPay: PublicMoney;
}

export const usePayment = (): UsePaymentResult => {
  const { cartWithSteps } = useCart();

  const priceToPay: PublicMoney = useMemo(() => {
    const depositInfo = cartWithSteps.data?.cart?.totals?.deposits?.find(
      (deposit) => deposit.option === DepositOption.DEPOSIT && deposit.price.amount > 0
    );

    return depositInfo
      ? depositInfo.price
      : cartWithSteps.data?.cart?.totals?.grand?.gross || { amount: 0, currencyCode: 'EUR' };
  }, [
    cartWithSteps.data?.cart?.totals?.grand?.gross?.amount,
    cartWithSteps.data?.cart?.totals?.deposits,
  ]);

  return {
    priceToPay,
  };
};
