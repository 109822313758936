import { VirtualPageProps } from '@guest-widgets/shared/src/utils/customerTracking/customerTrackingCreator';

import { Step } from '../contexts/routerContext/router';

export const virtualPageMapper: Record<
  Exclude<Step, 'purchaseFailure' | 'purchaseSuccess'>,
  VirtualPageProps
> = {
  product: {
    title: 'Product - Product',
    path: '/product',
  },
  upsell: {
    title: 'Product - Upsell',
    path: '/product/upsell',
  },
  cart: {
    title: 'Checkout - Cart',
    path: '/checkout/cart',
  },
  contact: {
    title: 'Checkout - Contact',
    path: '/checkout/contact',
  },
  checkout: {
    title: 'Checkout - Payment',
    path: '/checkout/payment',
  },
};
