import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { useLocalStorage } from '@guest-widgets/shared/src/hooks/useLocalStorage';

import { useSettings } from '../../settingsContext/settingsContext';
import { cartId, useSession } from '../cart/session/useSession';
import { CartItem } from '../cart/cart';
import { queryKeys } from '../../../queryKeys';
import { GuestType } from '../../productContext/product';
import { useValidateApiItemGetter } from '../../../hooks/useValidateApiItemGetter';

import { guestApi } from './guestApi';
import { mapToPricing } from './mapToPricing';
import { Quantity } from './dtos/request';
import type { Pricing } from './product';

interface PricingApiProps {
  experience: CartItem;
  quantity?: Quantity;
  guestTypesWithQuantity: GuestType[];
  isDateTimeSelectionComplete: boolean;
}

export const usePricingApi = ({
  experience,
  quantity,
  guestTypesWithQuantity,
  isDateTimeSelectionComplete,
}: PricingApiProps): UseQueryResult<Pricing> => {
  const [discountCodes] = useLocalStorage(`${cartId}-discounts`, []);
  const { locale, customerCode, isLoading } = useSettings();
  const { cartState, setErrors, getLastItemId } = useSession();
  const { getExperienceLineItems, getUpsellLineItems } = useValidateApiItemGetter();

  const isUpsell = experience.id?.includes('.');
  const {
    start,
    end,
    product: { productId },
    discount,
  } = experience;

  const quantities = Object.values(quantity ?? {}).reduce((a, b) => `${a}${b}`, '');
  const queryDeps = [queryKeys.PRICING, productId, quantities, start, end, discount?.code];

  const hasParameters = Boolean(
    locale &&
      customerCode &&
      productId &&
      isDateTimeSelectionComplete &&
      !isLoading &&
      (isUpsell ? experience.id : true)
  );

  const queryFn = async () => {
    const checkedItemId = (getLastItemId() + 1).toString();

    const response = await guestApi.getPricing(customerCode, {
      discountCodes,
      lineItems: isUpsell
        ? getUpsellLineItems(cartState.items, [experience], quantity)
        : getExperienceLineItems(cartState.items, experience, checkedItemId, quantity),
    });
    const { lineItems } = response.data;
    const error = lineItems.find((item) => item.lineId === checkedItemId)?.error?.message;
    setErrors(error ? [error] : [], response.data.isValid);
    return mapToPricing(guestTypesWithQuantity, response, quantity);
  };

  return useQuery({
    queryKey: queryDeps,
    queryFn,
    enabled: quantity ? hasParameters && !!quantity : hasParameters,
  });
};
