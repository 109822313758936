import { Box, Typography } from '@mui/material';
import QuantityErrorIcon from '@mui/icons-material/ProductionQuantityLimits';
import { useNumberFormatter } from '@guest-widgets/shared/src/hooks/useNumberFormatter';
import { styled } from '@guest-widgets/core';

import { useUpsellInSelection } from '../upsellInSelectionContext';
import { ErrorMessage } from '../../Error';

import { ImageWithFallback } from './ImageWithFallback';
import { SimpleQty } from './SimpleQty';
import { useInnerDrawer } from './useInnerDrawer';

export const SimpleLayout = () => {
  const { upsellInModal } = useUpsellInSelection();
  const {
    upsellApi: { data },
  } = useInnerDrawer();
  const {
    price,
    images,
    name,
    currency,
    companyInformation,
    information,
    totalQuantity,
  } = upsellInModal;
  const { formatPrice } = useNumberFormatter(companyInformation?.locale?.language);

  if (!Object.values(upsellInModal).length) return null;

  const { body } = information?.description || {};
  const itemPrice = price !== undefined ? formatPrice(price, currency) : '';

  return (
    <>
      <Box mb={4} mt={-10}>
        <ImageWithFallback src={images?.[0]?.url ?? ''} alt={images?.[0]?.alt} />
      </Box>
      <Typography variant="h3" pb={4}>
        {name}
      </Typography>
      {body && <Description variant="body1" pb={4} dangerouslySetInnerHTML={{ __html: body }} />}
      <SimpleQty item={upsellInModal} itemPrice={itemPrice} />
      <ErrorMessage
        mt={0}
        mb={4}
        show={data?.error?.errorType === 'quantity' && !!totalQuantity}
        message={data?.error?.message}
        icon={QuantityErrorIcon}
      />
    </>
  );
};

const Description = styled(Typography)({
  '& p': {
    marginBlockStart: 'unset',
    marginBlockEnd: 'unset',
  },
});
