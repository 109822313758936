import { useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import { styled } from '@mui/material';
import { booking } from '@guest-widgets/shared/src/classes/booking';
import { Calendar as BaseCalendar } from '@guest-widgets/shared/src/components/Calendar/Calendar';
import { isEqual } from 'date-fns';

import { useTimeslots } from '../../../../../contexts/timeslotsContext/timeslotsContext';
import { useSettings } from '../../../../../contexts/settingsContext/settingsContext';
import { LoadingContainer } from '../../../../../StyledSharedComponents';
import { ErrorMessage } from '../../../../../Error';
import { useCalendarNumberOfMonths } from '../useCalendarNumberOfMonths';
import { useDayModifiers } from '../useDayModifiers';
import { useAccumulatedTimeslots } from '../useAccumulatedTimeslots';

import { CalendarRangeDayPicker } from './CalendarRangeDayPicker';
import { TimeslotDayRange, useCalendarRange } from './useCalendarRange';

interface CalendarProps {
  onSelect: (range: TimeslotDayRange) => void;
  selected?: TimeslotDayRange;
  limitMonths?: number;
  className?: string;
  autoSelect?: boolean;
}

export const CalendarRange = ({
  onSelect,
  selected,
  limitMonths,
  className,
  autoSelect,
}: CalendarProps) => {
  const { locale } = useSettings();
  const { timeslot, setFilters, filters } = useTimeslots();
  const allAvailableDays = useAccumulatedTimeslots();
  const numberOfMonths = useCalendarNumberOfMonths(limitMonths);
  const { handleAutoSelect, handleHover, handleMouseLeave, handleSelect } = useCalendarRange({
    onSelect,
    selected,
    autoSelect,
  });
  const trafficLightStatus = useDayModifiers();
  const firstMonth = filters.dateFrom ?? timeslot.data?.days?.[0]?.day ?? new Date();

  useEffect(handleAutoSelect, [allAvailableDays[0]?.day.valueOf()]);

  const selectedDateRange = (() => {
    const from = selected?.from?.day;
    const to = selected?.to?.day;

    return {
      from,
      to: from && to && isEqual(from, to) ? undefined : to,
    };
  })();

  return (
    <Container className={className} data-testid="calendar-range">
      <BaseCalendar
        language={locale}
        components={{ Day: CalendarRangeDayPicker }}
        mode="range"
        onSelect={handleSelect}
        onDayMouseEnter={(day, __, event) => handleHover(day, event)}
        onDayMouseLeave={(_, __, event) => handleMouseLeave(event)}
        selected={selectedDateRange}
        month={firstMonth}
        onMonthChange={(dateFrom: Date) => setFilters({ dateFrom })}
        numberOfMonths={numberOfMonths}
        fromMonth={new Date()}
        fromDate={new Date()}
        className={booking.calendar}
        modifiers={trafficLightStatus}
      />
      <ErrorMessage
        show={selected?.isAvailable === false}
        messageKey="unavailable"
        icon={EventBusyIcon}
      />
      {timeslot.isFetching && (
        <LoadingContainer pt={8}>
          <CircularProgress size={50} />
        </LoadingContainer>
      )}
    </Container>
  );
};

const Container = styled('div')({
  position: 'relative',
});
