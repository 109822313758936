import { MouseEvent } from 'react';
import {
  addDays,
  differenceInCalendarDays,
  endOfMonth,
  getDate,
  getMonth,
  isAfter,
  isBefore,
  isSameDay,
  parse,
  startOfMonth,
  subMonths,
} from 'date-fns';

import type { TimeslotsDay } from '../../../../../contexts/timeslotsContext/timeslots';
import { useTimeslots } from '../../../../../contexts/timeslotsContext/timeslotsContext';

import { getRulesByType } from './utils';

export const useHoverCalculations = () => {
  const {
    filters,
    product: { allocationType },
  } = useTimeslots();

  /** Get index relative to position of hovered day and its month (next, previous or same) */
  const getSelectedIndex = (
    hoveredDay: Date,
    buttons: HTMLButtonElement[],
    from: TimeslotsDay,
    to?: TimeslotsDay
  ) => {
    const { dateFrom = new Date() } = filters;
    const { isBeforeFirstDisplayedDay, isAfterLastDisplayedDay } =
      calculateSelectionVisibility(dateFrom, buttons, from.day, to?.day) || {};

    if (isBeforeFirstDisplayedDay) {
      return calculateIndexInPreviousMonths(hoveredDay, from);
    }

    if (isAfterLastDisplayedDay) {
      return calculateIndexInNextMonths(dateFrom, from, to);
    }

    return calculateIndexInDisplayedDays(buttons, hoveredDay, dateFrom, from, to);
  };

  /** Get the min and max duration of available days */
  const getRules = (button: HTMLButtonElement, allAvailableDays: TimeslotsDay[]) => {
    const { dayButton, monthButton } = getDayAndMonthFromButton(button);
    const availableDay = allAvailableDays.find(
      (date) => getDate(date.day) === dayButton && getMonth(date.day) === monthButton
    );
    const rules = availableDay?.availabilityTimes?.[0]?.rules;

    return getRulesByType(allocationType, rules);
  };

  /** Get an array of button elements from months on the screen */
  const getButtonsFromMonth = (event: MouseEvent) => {
    const currentButton = event.target as HTMLButtonElement;
    const rdpMonths = currentButton.closest('.rdp-months');
    const tbodies = rdpMonths && Array.from(rdpMonths.querySelectorAll('tbody'));
    const buttons =
      tbodies && tbodies.flatMap((tbody) => Array.from(tbody.querySelectorAll('button')));

    return { currentButton, buttons };
  };

  return { getSelectedIndex, getRules, getButtonsFromMonth };
};

/** Get a day and a month from label of button element */
const getDayAndMonthFromButton = (button: HTMLButtonElement) => {
  const dayAndMonth = button.ariaLabel!.split(' ');
  const dayButton = Number(dayAndMonth[0].slice(0, -2));
  const monthButton = parse(dayAndMonth[1], 'MMMM', new Date()).getMonth();
  return { dayButton, monthButton };
};

/** Check if a button element (for instance: in a array) is the one that is selected */
const isButtonSameAsSelected = (
  hoveredDay: Date,
  index: number,
  dateForMonthGeneration: Date,
  from: TimeslotsDay,
  to?: TimeslotsDay
) => {
  const firstDayInScreen = startOfMonth(dateForMonthGeneration);

  return !!to?.day && isBefore(hoveredDay, from.day)
    ? isSameDay(to?.day, addDays(firstDayInScreen, index))
    : isSameDay(from.day, addDays(firstDayInScreen, index));
};

/** Check if selected days are out of the screen and their position related to displayed days */
const calculateSelectionVisibility = (
  dateForMonthGeneration: Date,
  buttons: HTMLButtonElement[],
  from: Date,
  to?: Date
) => {
  const firstDayInScreen = startOfMonth(dateForMonthGeneration);
  const lastDayInScreen = endOfMonth(addDays(firstDayInScreen, buttons.length - 1));
  const isBeforeFirstDisplayedDay = isBefore(to ?? from, firstDayInScreen);
  const isAfterLastDisplayedDay = isAfter(from, lastDayInScreen);

  return { isBeforeFirstDisplayedDay, isAfterLastDisplayedDay };
};

/** Calculate the index of a selected button that is among displayed days based on hovered day */
const calculateIndexInDisplayedDays = (
  buttons: HTMLButtonElement[],
  hoveredDay: Date,
  dateForMonthGeneration: Date,
  from: TimeslotsDay,
  to?: TimeslotsDay
) =>
  buttons.findIndex((_, index) =>
    isButtonSameAsSelected(hoveredDay, index, dateForMonthGeneration, from, to)
  );

/** Calculate the index of a selected button that is in previous months based on hovered day */
const calculateIndexInNextMonths = (
  dateForMonthGeneration: Date,
  from: TimeslotsDay,
  to?: TimeslotsDay
) => {
  const firstDayInScreen = startOfMonth(dateForMonthGeneration);

  return differenceInCalendarDays(to?.day ?? from.day, firstDayInScreen);
};

/** Calculate the index of a selected button that is in next months based on hovered day */
const calculateIndexInPreviousMonths = (hoveredDay: Date, from: TimeslotsDay) =>
  differenceInCalendarDays(from.day, endOfMonth(subMonths(hoveredDay, 1))) - 1;
