import { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import QuantityErrorIcon from '@mui/icons-material/ProductionQuantityLimits';
import { QuantitySelector } from '@guest-widgets/shared/src/components/QuantitySelector/QuantitySelector';
import { booking } from '@guest-widgets/shared/src/classes/booking';
import { useNumberFormatter } from '@guest-widgets/shared/src/hooks/useNumberFormatter';
import { Text } from '@eo-locale/react';

import { ErrorMessage } from '../../Error';
import { mapToPublicMoney } from '../../contexts/apiContext/cart/mapToCart';
import { mapToInitialQuantity } from '../../contexts/apiContext/product/mapToPricing';
import { useProduct } from '../../contexts/productContext/productContext';

import { GuestRate, useProductInSelection } from './productInSelectionContext';

export const QuantitySelection = () => {
  const [guests, setGuests] = useState<GuestRate[]>([]);
  const { companyInformation } = useProduct();
  const { formatPrice } = useNumberFormatter(companyInformation?.locale?.language);
  const {
    quantityChanged,
    setQuantityChanged,
    quantity,
    setQuantity,
    currency,
    pricingApi,
  } = useProductInSelection();
  const { guestRates, error, isValid } = pricingApi.data || {};

  useEffect(() => {
    if (!!guestRates?.length && (isValid || !guests.length)) {
      setGuests(guestRates);
    }
  }, [JSON.stringify(guestRates)]);

  const handleQuantityChange = (guestType: string) => (newQuantity: number) => {
    if (!quantity) {
      const initialQuantity = mapToInitialQuantity(guestRates);
      return setQuantity({ ...initialQuantity, [guestType]: newQuantity });
    }
    if (!quantityChanged) setQuantityChanged?.(true);

    setQuantity({ ...quantity, [guestType]: newQuantity });
  };

  return (
    <>
      <Title variant="h4" className={booking.step.title}>
        <Text id="participants" />
      </Title>
      <StyledQuantityContainer>
        {guests?.map(({ id, label, rate }: GuestRate) => (
          <StyledQuantitySelectorContainer key={id}>
            <Typography lineHeight={1} flexGrow={1}>
              {label}
            </Typography>
            {!!rate && (
              <Typography mr={3} textAlign="right">{`${formatPrice(
                mapToPublicMoney(rate).amount,
                currency
              )}`}</Typography>
            )}
            <QuantitySelector
              quantity={quantity?.[id] || 0}
              min={1}
              handleQuantityChange={handleQuantityChange(id)}
              position="end"
            />
          </StyledQuantitySelectorContainer>
        ))}
        <ErrorMessage
          mb={0}
          mt={2}
          show={error?.errorType === 'quantity' && !!quantity}
          message={error?.message}
          icon={QuantityErrorIcon}
        />
      </StyledQuantityContainer>
    </>
  );
};

export const StyledQuantitySelectorContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
});

export const StyledQuantityContainer = styled(Box)(({ theme: { spacing, border } }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing(4),
  paddingTop: spacing(3),
  paddingBottom: spacing(5),
  paddingLeft: 0,
  paddingRight: 0,
  borderBottom: border,
}));

const Title = styled(Typography)(({ theme: { spacing, border } }) => ({
  borderTop: border,
  paddingTop: spacing(4),
  marginTop: spacing(4),
  marginBottom: spacing(3),
}));
