import { Button } from '@mui/material';
import { classes } from '@guest-widgets/shared/src/classes';
import { Text } from '@eo-locale/react';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { ICON_BASE_SIZE, LoadingButton } from '@guest-widgets/shared/src/components/LoadingButton';
import { styled } from '@guest-widgets/core';
import { useNumberFormatter } from '@guest-widgets/shared/src/hooks/useNumberFormatter';

import { NavigationContainer } from '../../StyledSharedComponents';
import { useRouter } from '../../contexts/routerContext/routerContext';
import { PublicMoney } from '../../contexts/cartContext/cart';
import { useProduct } from '../../contexts/productContext/productContext';

interface NavigationProps {
  action: () => void;
  paymentInProgress: boolean;
  priceToPay: PublicMoney;
}

export const Navigation = ({ action, paymentInProgress, priceToPay }: NavigationProps) => {
  const { setCurrentStep } = useRouter();
  const { companyInformation } = useProduct();
  const { formatPrice } = useNumberFormatter(companyInformation?.locale?.language);

  return (
    <NavigationContainer>
      <PayButton
        loading={paymentInProgress}
        variant="contained"
        color="primary"
        size="large"
        iconSize={ICON_BASE_SIZE}
        fullWidth
        type="submit"
        onClick={action}
      >
        <Text id="pay-variable" price={formatPrice(priceToPay.amount, priceToPay.currencyCode)} />
      </PayButton>

      <Button
        variant="outlined"
        color="primary"
        startIcon={<ChevronLeftIcon />}
        onClick={() => setCurrentStep('product')}
        fullWidth
        className={classes.booking.buttons.back}
      >
        <Text id="continue-shopping" />
      </Button>
    </NavigationContainer>
  );
};

const PayButton = styled(LoadingButton)(({ theme: { spacing } }) => ({
  marginTop: spacing(3),
}));
