import { styled } from '@guest-widgets/core';
import DateErrorIcon from '@mui/icons-material/EventBusy';

import { CalendarRange } from '../Steps/Day/Calendar/CalendarRange/CalendarRange';
import { Calendar as CalendarSimple } from '../Steps/Day/Calendar/Calendar';
import { ErrorMessage } from '../../Error';
import { TimeslotDayRange } from '../Steps/Day/Calendar/CalendarRange/useCalendarRange';

interface CalendarProps {
  requiresDateRange: boolean;
  setDate: (date?: TimeslotDayRange) => void;
  date?: TimeslotDayRange;
  limitOfMonths?: number;
  hasError: boolean;
  error?: { errorType: string; message: string };
}

export const Calendar = ({
  requiresDateRange,
  setDate,
  date,
  limitOfMonths,
  hasError,
  error,
}: CalendarProps) => (
  <CalendarContainer>
    {requiresDateRange ? (
      <CalendarRange onSelect={setDate} selected={date} limitMonths={limitOfMonths} autoSelect />
    ) : (
      <CalendarSimple
        onSelect={(date) => setDate({ from: date })}
        selected={date?.from}
        limitMonths={limitOfMonths}
        autoSelect
      />
    )}
    <ErrorMessage
      show={hasError && error?.errorType === 'date'}
      message={error?.message}
      icon={DateErrorIcon}
    />
  </CalendarContainer>
);

const CalendarContainer = styled('div')({
  '& div.rdp-months': {
    marginLeft: 0,
    marginRight: 0,
  },
});
