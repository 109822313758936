import { Typography, Box, Divider, Button } from '@mui/material';
import { styled } from '@guest-widgets/core';
import { Text } from '@eo-locale/react';
import { booking } from '@guest-widgets/shared/src/classes/booking';
import { green, grey } from '@mui/material/colors';
import { format } from 'date-fns';
import { mapMomentToDateFormat } from '@guest-widgets/shared/src/mappers/momentToDateFnsMapper';
import { useEffect } from 'react';
import { handleTrackingEvent } from '@guest-widgets/shared/src/utils/customerTracking/utils/trackingEventsHandler';
import { TrackingEventType } from '@guest-widgets/shared/src/utils/customerTracking/types';

import { useContactContext } from '../contexts/contactContext/contactContext';
import { useRefreshPaymentSuccessPage } from '../hooks/payment/useRefreshSuccessPage';
import { useProduct } from '../contexts/productContext/productContext';
import { useCart } from '../contexts/cartContext/cartContext';
import { Totals } from '../common/Totals';
import { useRouter } from '../contexts/routerContext/routerContext';
import { usePayment } from '../hooks/cart/usePayment';

import { SoldItems } from './SoldItems';

const PurchaseSuccess = () => {
  //TODO Check if we really need booking date
  const bookingDate = new Date();

  const { setCurrentStep } = useRouter();
  const { contactForm } = useContactContext();
  const { companyInformation } = useProduct();
  const { destroy } = useRefreshPaymentSuccessPage();
  const { cartWithSteps } = useCart();
  const { priceToPay } = usePayment();

  const bookingNumber = cartWithSteps.data?.cart?.bookingId;

  const goToNewBooking = () => {
    window.location.reload();
    destroy.mutate();
  };

  useEffect(() => {
    if (cartWithSteps.data?.cart?.id && !bookingNumber) {
      setCurrentStep('product');
    }
  }, [cartWithSteps.data?.cart?.id, bookingNumber]);

  if (!bookingNumber) {
    return null;
  }

  const items = cartWithSteps.data?.cart?.items ?? [];
  const currency = priceToPay.currencyCode;

  handleTrackingEvent(TrackingEventType.PURCHASE, { bookingNumber, items, currency });

  return (
    <Container>
      <ContentContainer>
        <SuccessTitle variant={'h1'}>
          <Text id="purchase-success-title" />
        </SuccessTitle>
        <SuccessMessage align={'center'} variant={'body1'}>
          <Text
            id="purchase-success-message"
            html
            email={`<strong>${contactForm?.contact.customer_email ?? ''}</strong>`}
          />
        </SuccessMessage>

        <OrderDetails>
          <Typography variant="h1">
            <Text id="order-details" />
          </Typography>

          <BookingInfo>
            <Box>
              <BookingInfoItemTitle variant="body2">
                <Text id="booking-number" />:
              </BookingInfoItemTitle>
              {bookingNumber}
            </Box>
            <Box>
              <BookingInfoItemTitle variant="body2">
                <Text id="booking-date" />:
              </BookingInfoItemTitle>
              {format(
                bookingDate,
                mapMomentToDateFormat(companyInformation?.locale?.dateFormatMoment!)
              )}
            </Box>
          </BookingInfo>

          <SoldItems />

          <TotalsContainer>
            <Totals />
          </TotalsContainer>

          <StyledDivider />

          <NewBookingButtonContainer>
            <NewBookingButton
              color="primary"
              variant="contained"
              size="medium"
              className={`${booking.buttons.button} ${booking.buttons.checkout}`}
              onClick={goToNewBooking}
            >
              <Text id="new-booking" />
            </NewBookingButton>
          </NewBookingButtonContainer>
        </OrderDetails>
      </ContentContainer>
    </Container>
  );
};

export default PurchaseSuccess;

const TotalsContainer = styled(Box)(({ theme: { spacing } }) => ({
  marginTop: spacing(4),
  marginBottom: spacing(3),
}));

const NewBookingButtonContainer = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'row',
  width: '100%',
  marginTop: spacing(5),
  marginBottom: spacing(3),
}));

const NewBookingButton = styled(Button)(() => ({
  flexGrow: 1,
  maxWidth: '552px',
}));

const Container = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: grey[50],
  alignItems: 'center',
}));

export const ContentContainer = styled(Box)(({ theme: { spacing } }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing(4),
  paddingTop: spacing(4),
  alignItems: 'center',
  maxWidth: '1150px',
  width: '100%',
}));

const StyledDivider = styled(Divider)(({ theme: { border } }) => ({
  border: border,
}));

const SuccessTitle = styled(Typography)(({ theme: { spacing } }) => ({
  color: green['800'],
  textAlign: 'center',
  padding: spacing(2),
  paddingBottom: 0,
}));

const SuccessMessage = styled(Typography)(({ theme: { spacing } }) => ({
  paddingBottom: spacing(3),
  paddingLeft: spacing(2),
  paddingRight: spacing(2),
}));

export const OrderDetails = styled(Box)(({ theme: { spacing, palette, border }, widgetArea }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing(4),
  padding: spacing(6),
  backgroundColor: palette.common.white,
  width: '100%',
  border,
  borderRadius: widgetArea.isSmall ? 0 : spacing(1),
  borderRightWidth: widgetArea.isSmall ? 0 : 2,
  borderLeftWidth: widgetArea.isSmall ? 0 : 2,
}));

export const BookingInfo = styled(Box)(({ theme: { spacing }, widgetArea }) => ({
  display: 'flex',
  flexDirection: widgetArea.isSmall ? 'column' : 'row',
  gap: widgetArea.isSmall ? spacing(4) : 0,
  paddingTop: spacing(1),
  '& > *': {
    flexGrow: 1,
  },
}));

export const BookingInfoItemTitle = styled(Typography)(({ theme: { spacing } }) => ({
  opacity: 0.5,
  paddingBottom: spacing(1),
}));
