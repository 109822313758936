import { useProduct } from '../../contexts/productContext/productContext';

import { ProductInSelectionProvider } from './productInSelectionContext';
import { ProductInner } from './ProductInner';

export const GuestProduct = () => {
  const product = useProduct();

  return (
    <ProductInSelectionProvider product={product}>
      <ProductInner />
    </ProductInSelectionProvider>
  );
};
