import { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { Text } from '@eo-locale/react';
import { addDays } from 'date-fns';
import { booking } from '@guest-widgets/shared/src/classes/booking';
import { useWidget } from '@guest-widgets/core';

import { Calendar } from '../../Product/Guest/Calendar';
import { QuantitySelection } from '../../Product/Guest/QuantitySelection';
import { Time } from '../../Product/Guest/Time';
import { TimeslotsContextProvider } from '../../contexts/timeslotsContext/timeslotsContext';
import { useUpsellInSelection } from '../upsellInSelectionContext';
import {
  ProductInSelectionProvider,
  useProductInSelection,
} from '../../Product/Guest/productInSelectionContext';
import { UpsellItem } from '../../contexts/upsellContext/upsell';

import { Subtotal } from './Subtotal';
import { useInnerDrawer } from './useInnerDrawer';

interface DateTimeQtyProps {
  item: UpsellItem;
}

const DateTimeQtyInner = ({ item }: DateTimeQtyProps) => {
  const { isMobileSize } = useWidget();
  const { handleQuantityChange, setAddToCartErrors } = useUpsellInSelection();
  const {
    requiresTimeSelection,
    requiresDateRange,
    setDate,
    date,
    time,
    quantity,
    currency,
  } = useProductInSelection();
  const { upsellApi } = useInnerDrawer();

  const { error, data } = upsellApi;
  const { error: pricingError } = data || {};
  const { subtotal } = data?.totals || {};
  const rateLimitError = ((error as Error)?.message ?? '').includes('429');
  const hasError = !!error || !!pricingError || rateLimitError;

  const quantityDependency = quantity
    ? Object.values(quantity).reduce((acc, value) => acc + value, 0)
    : 0;
  const endDateRange =
    item?.allocationType === 'day' && date?.to?.day ? addDays(date.to.day, 1) : date?.to?.day;

  // Updates upsell when date, time or quantity are chaged in modal
  useEffect(() => {
    if (quantity === undefined) return;

    const start = time?.startTime ?? date?.from?.day;
    const end = time?.endTime ?? endDateRange;

    handleQuantityChange({ upsell: item, quantity, start, end, isModal: true });
  }, [quantityDependency, date?.from?.day.valueOf(), date?.to?.day.valueOf()]);

  // Resets errors when date, time or quantity is changed
  useEffect(() => {
    setAddToCartErrors([]);
  }, [
    date?.from?.day.toLocaleDateString(),
    date?.to?.day.toLocaleDateString(),
    time?.startTime?.toLocaleTimeString(),
    time?.endTime?.toLocaleTimeString(),
    Object.values(quantity ?? {}).join('_'),
  ]);

  return (
    <>
      <Box pt={isMobileSize() ? 2 : 0} className={booking.steps}>
        <Typography variant="h2" mb={4} component="h2" className={booking.step.title}>
          <Text id="select-your-preferred-date-and-time" />
        </Typography>
        <Typography variant="h4" mb={4} component="h3" className={booking.step.title}>
          <Text id="date" />
        </Typography>
        <Calendar
          requiresDateRange={requiresDateRange}
          setDate={setDate}
          date={date}
          limitOfMonths={1}
          hasError={hasError}
          error={pricingError}
        />
        {requiresTimeSelection && <Time hasError={hasError} error={pricingError} />}
      </Box>
      <QuantitySelection />
      <Subtotal price={!!quantity && subtotal ? subtotal.amount : 0} currency={currency} />
    </>
  );
};

export const DateTimeQty = ({ item }: DateTimeQtyProps) => (
  <TimeslotsContextProvider product={item} showUpsells={true}>
    <ProductInSelectionProvider product={item}>
      <DateTimeQtyInner item={item} />
    </ProductInSelectionProvider>
  </TimeslotsContextProvider>
);
