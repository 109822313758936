import { useMemo } from 'react';
import { useLocalStorage } from '@guest-widgets/shared/src/hooks/useLocalStorage';
import { useNumberFormatter } from '@guest-widgets/shared/src/hooks/useNumberFormatter';
import { SvgIconTypeMap, SxProps, TypographyVariant } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { styled } from '@guest-widgets/core';
import SellIcon from '@mui/icons-material/Sell';

import { useCart } from '../../contexts/cartContext/cartContext';
import { cartId } from '../../contexts/apiContext/cart/session/useSession';
import { useRouter } from '../../contexts/routerContext/routerContext';
import { CartDeposit, DepositOption } from '../../contexts/apiContext/cart/cart';
import { useUpsell } from '../../contexts/upsellContext/upsellContext';
import { Tax } from '../../contexts/cartContext/cart';

export interface TotalRow {
  title: string;
  variable?: Object;
  icon?: OverridableComponent<SvgIconTypeMap>;
  amount?: number;
  currencyCode: string;
  render: boolean;
  formatType?: 'negative' | 'positive';
  variant?: TypographyVariant;
  sx?: SxProps;
  withColor?: boolean;
}

interface UseTotalResult {
  totalRows: TotalRow[];
}

export const useTotal = (): UseTotalResult => {
  const { cartWithSteps } = useCart();
  const { currentStep } = useRouter();
  const { upsells } = useUpsell();
  const [[discountCode]] = useLocalStorage(`${cartId}-discounts`, []);
  const [deposit] = useLocalStorage(`${cartId}-deposit`, {} as CartDeposit);
  const { formatPrice } = useNumberFormatter();
  const isCart = currentStep === 'cart';
  const isCheckout = currentStep === 'checkout';
  const isPurchaseSuccess = currentStep === 'purchaseSuccess';
  const isPayDepositSelected = deposit?.option === DepositOption.DEPOSIT;

  const totalRows: TotalRow[] = useMemo(() => {
    if (!cartWithSteps.data?.cart) return [];

    const { totals } = cartWithSteps.data.cart;
    const { subtotal, grand, deposits, discount, ibaFee, taxtotal } = totals;
    const depositInfo = deposits?.find((deposit) => deposit.option === DepositOption.DEPOSIT);
    // TODO: Remove this to implement deposit optinal section once the pay option can be passed to API.
    const isOptional = false;
    // const isOptional = deposits?.every((deposit) => !!deposit.price.amount);
    const highlightDeposit = isOptional ? isPayDepositSelected : !!depositInfo?.price?.amount;
    // TODO: Remove this to implement discount section once the discount is passed from API.
    const isDiscountShown = false;

    const { price: depositPrice } = depositInfo || { price: { amount: 0, currencyCode: 'EUR' } };

    return [
      {
        title: 'subtotal',
        amount: subtotal.gross.amount!,
        currencyCode: subtotal.gross.currencyCode!,
        render: !isPurchaseSuccess,
      },
      {
        title: discountCode,
        icon: FlippedSellIcon,
        // TODO: Include correct discount amount when Checkfront provides it.
        amount: subtotal.gross.amount! - discount?.amount!,
        currencyCode: grand?.gross.currencyCode!,
        formatType: 'negative' as TotalRow['formatType'],
        render: isCart && !!discountCode && !!discount && isDiscountShown && !isPurchaseSuccess,
      },
      {
        title: 'taxes',
        amount: taxtotal?.gross.amount,
        currencyCode: taxtotal?.gross.currencyCode,
        render: !!taxtotal?.gross.amount && !isPurchaseSuccess,
      },
      {
        title: 'currency-adjustment',
        amount: ibaFee?.gross.amount!,
        currencyCode: ibaFee?.gross.currencyCode!,
        render: !!ibaFee && !isPurchaseSuccess,
      },
      {
        title: 'grand-total',
        amount: grand?.gross.amount!,
        currencyCode: grand?.gross.currencyCode!,
        variant:
          (isCart || isCheckout || isPurchaseSuccess) && highlightDeposit
            ? ('body2' as TypographyVariant)
            : ('h2' as TypographyVariant),
        sx: { fontWeight: 'bold' },
        withColor: true,
        render: true,
      },
      {
        title: isPurchaseSuccess ? 'paid' : 'due-now',
        amount: depositPrice.amount,
        currencyCode: depositPrice.currencyCode,
        variant: highlightDeposit ? ('h2' as TypographyVariant) : ('body2' as TypographyVariant),
        sx: { fontWeight: 'bold' },
        withColor: true,
        render: (isCart || isCheckout || isPurchaseSuccess) && highlightDeposit,
      },
      {
        title: isPurchaseSuccess
          ? 'you-paid-variable-deposit-with-variable-due'
          : 'pay-variable-deposit-today-with-variable-due-later',
        variable: {
          deposit: formatPrice(depositPrice.amount, depositPrice.currencyCode),
          due: formatPrice(grand?.gross.amount - depositPrice.amount!, depositPrice.currencyCode),
        },
        amount: undefined,
        currencyCode: grand?.gross.currencyCode!,
        render:
          (isCart || isCheckout || isPurchaseSuccess) &&
          !isOptional &&
          !!depositInfo?.price?.amount,
      },
    ];
  }, [
    cartWithSteps?.data?.cart?.totals.subtotal.gross.amount,
    deposit?.option,
    upsells[0]?.parent.lineId,
  ]);

  return {
    totalRows,
  };
};

const FlippedSellIcon = styled(SellIcon)({
  transform: 'scaleX(-1)',
  paddingRight: 0,
  paddingLeft: 4,
}) as OverridableComponent<SvgIconTypeMap>;
