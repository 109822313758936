import { useEffect } from 'react';

import { useCartApi } from '../../contexts/apiContext/cart/useCartApi';

export const useRefreshPaymentSuccessPage = () => {
  const { destroy } = useCartApi();

  useEffect(() => {
    const handleRefresh = (event: any) => {
      event.preventDefault();
      destroy.mutate();
    };

    window.addEventListener('unload', handleRefresh);

    return () => {
      window.removeEventListener('unload', handleRefresh);
    };
  }, []);

  return {
    destroy,
  };
};
