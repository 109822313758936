import React, { useRef } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useLocalStorage } from '@guest-widgets/shared/src/hooks/useLocalStorage';
import { regionApi } from '@guest-widgets/shared/src/apis/guestExperience';

import type { Contact, ContactForm } from '../../contactContext/contact';
import { useSettings } from '../../settingsContext/settingsContext';
import { useCart } from '../../cartContext/cartContext';
import { useRouter } from '../../routerContext/routerContext';
import { guestApi } from '../product/guestApi';
import { queryKeys } from '../../../queryKeys';

import type { ContactApi, ContactFromApi } from './types';
import { mapToContact } from './mapToContact';
import { useContactSubmit } from './mutations/useContactSubmit';

const localStorageContactKey = queryKeys.CONTACT;

export const useContactApi = (initialData?: Contact): ContactApi => {
  const { cartWithSteps } = useCart();
  const { customerCode } = useSettings();
  const { currentStep } = useRouter();
  const stringOfIds = useRef<string>();
  const [contactForm, setContactForm] = useLocalStorage<ContactForm | undefined>(
    localStorageContactKey,
    undefined
  );

  const cartItems = cartWithSteps.data?.cart?.items || [];
  const productIds = cartItems
    .map(({ product: { productId } }) => productId)
    .reduce<Array<string>>((productIds, productId) => {
      return productIds.includes(productId) ? productIds : [...productIds, productId];
    }, [])
    .sort((a, b) => (a > b ? 1 : -1));
  const hasChanged = stringOfIds.current !== productIds.join(',');
  const isContact = currentStep === 'contact';

  const queryFn = async (): Promise<ContactFromApi> => {
    stringOfIds.current = productIds.join(',');
    const fields = await guestApi.getContactFields(customerCode, productIds);
    // Force fetching regions if data is retrieved from local storage
    if (contactForm?.contact?.customer_country) {
      const regions = await regionApi.getRegions(contactForm?.contact.customer_country as string);
      fields.forEach((field) => {
        if (field.id === 'customer_region') {
          field.choices = regions;
        }
      });
    }
    return Promise.resolve({
      contact: mapToContact(fields, contactForm),
    });
  };

  return {
    contact: useQuery({
      queryKey: [queryKeys.CONTACT, productIds],
      queryFn,
      placeholderData: initialData ? { contact: initialData } : undefined,
      enabled: !!productIds.length && hasChanged && isContact,
    }),
    contactSubmit: useContactSubmit(setContactForm),
    contactForm,
    destroy: () => setContactForm(undefined),
  };
};
