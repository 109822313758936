import { customerTracking } from '@guest-widgets/shared/src/utils/customerTracking/customerTracking';
import { useEffect, useState } from 'react';
import addDays from 'date-fns/addDays';
import { handleTrackingEvent } from '@guest-widgets/shared/src/utils/customerTracking/utils/trackingEventsHandler';
import { TrackingEventType } from '@guest-widgets/shared/src/utils/customerTracking/types';

import { formatDate } from '../../contexts/apiContext/product/formatDate';
import { getItemSimilarityKey } from '../../contexts/apiContext/cart/session/addToCart';
import { CartItem } from '../../contexts/apiContext/cart/cart';
import { useRouter } from '../../contexts/routerContext/routerContext';
import { useSession } from '../../contexts/apiContext/cart/session/useSession';
import { useCart } from '../../contexts/cartContext/cartContext';
import { useProductInSelection } from '../../Product/Guest/productInSelectionContext';

export const useInnerProduct = () => {
  const [addToCartErrors, setAddToCartErrors] = useState<string[]>([]);
  const { nextStep } = useRouter();
  const { addToCart } = useCart();
  const { generateItemId } = useSession();
  const {
    requiresDateRange,
    isComplete,
    date,
    time,
    requiresTimeSelection,
    setDate,
    currency,
    quantity,
    setQuantity,
    name,
    pricingApi,
    ...product
  } = useProductInSelection();
  const { total, error } = pricingApi.data || {};
  const rateLimitError = ((pricingApi?.error as Error)?.message ?? '').includes('429');

  const endDateRange =
    product.allocationType === 'day' && date?.to?.day ? addDays(date.to.day, 1) : date?.to?.day;

  useEffect(() => {
    const addedItemError = addToCart.data?.addedItemError;
    setAddToCartErrors(addedItemError ? [addedItemError] : []);

    if (addToCart.isSuccess && !addedItemError) {
      nextStep();
      addToCart.reset();
    }
  }, [addToCart.isSuccess]);

  useEffect(() => {
    setAddToCartErrors([]);
  }, [
    date?.from?.day.toLocaleDateString(),
    date?.to?.day.toLocaleDateString(),
    time?.startTime?.toLocaleTimeString(),
    time?.endTime?.toLocaleTimeString(),
    Object.values(quantity ?? {}).join('_'),
  ]);

  const addToCartHandler = () => {
    if (error) {
      return;
    }

    const obj: CartItem = {
      id: generateItemId(),
      start: formatDate(date?.from?.day!),
      end: formatDate(endDateRange),
      product: {
        name,
        productId: product.productId,
        allocationType: product.allocationType,
        brand: product?.companyInformation?.name || '',
        image: product.images?.[0],
      },
      guestTypes: quantity!,
      price: {
        total: {
          amount: total || 0,
          currencyCode: currency,
        },
      },
      totalQuantity: Object.values(quantity!).reduce((acc, curr) => acc + curr, 0),
    };

    addToCart.mutateAsync([obj]).then(({ cartWithSteps }) => {
      const targetedItem = cartWithSteps.cart.items.find(
        (item) => getItemSimilarityKey(item) === getItemSimilarityKey(obj)
      );

      const itemToSend = { ...targetedItem!, guestTypes: obj.guestTypes };

      handleTrackingEvent(TrackingEventType.ADD_TO_CART, {
        addedItem: itemToSend,
        currency,
      });
    });
  };

  const hasError = (requiresDateRange && !date?.isAvailable) || !!error || rateLimitError;

  return {
    requiresDateRange,
    setDate,
    date,
    product,
    requiresTimeSelection,
    hasError,
    error,
    addToCartHandler,
    name,
    quantity,
    setQuantity,
    total,
    currency,
    addToCartErrors,
    addToCart,
    pricingApi,
    isComplete,
    guestTypes: product.guestTypes,
    rateLimitError,
  };
};
