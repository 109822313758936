import { useEffect, useState } from 'react';
import isSameMonth from 'date-fns/isSameMonth';
import isSameYear from 'date-fns/isSameYear';
import { usePrevious } from '@guest-widgets/shared/src/hooks/usePrevious';

import { TimeslotsDay } from '../../../../contexts/timeslotsContext/timeslots';
import { useTimeslots } from '../../../../contexts/timeslotsContext/timeslotsContext';

/** Since when user navigates through months, timeslots are replaced by new month timeslots,
 * This hook returns all timeslots already requested from api for one product.
 * And it is reset when new product is called */
export const useAccumulatedTimeslots = (): TimeslotsDay[] => {
  const [allAvailableDays, setAllAvailableDays] = useState<TimeslotsDay[]>([]);
  const {
    timeslot,
    product: { productId },
  } = useTimeslots();
  const previousProduct = usePrevious(productId);
  const timeslotDays = timeslot?.data?.days;

  useEffect(() => {
    if (productId && previousProduct !== productId) {
      setAllAvailableDays([]);
    }
  }, [productId]);

  useEffect(() => {
    const availableTimeslots = timeslotDays?.filter(
      ({ availabilityTimes }) => availabilityTimes.length > 0
    );

    if (!availableTimeslots?.length) {
      setAllAvailableDays([]);
      return;
    }

    const monthsNotInTimeslotDays = allAvailableDays.filter((availableTimeslot) => {
      return !availableTimeslots.find(
        (timeslot) =>
          isSameMonth(availableTimeslot?.day, timeslot?.day) &&
          isSameYear(availableTimeslot?.day, timeslot?.day)
      );
    });

    if (!monthsNotInTimeslotDays.length) {
      setAllAvailableDays(availableTimeslots);
      return;
    }

    const mergedAvailableTimeslots = [...availableTimeslots, ...monthsNotInTimeslotDays];
    mergedAvailableTimeslots.sort((a, b) => a.day.getTime() - b.day.getTime());

    setAllAvailableDays(mergedAvailableTimeslots);
  }, [timeslotDays?.[0]?.day.valueOf(), timeslotDays?.slice(-1)[0]?.day.valueOf()]);

  return allAvailableDays;
};
