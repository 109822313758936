import { useNumberFormatter } from '@guest-widgets/shared/src/hooks/useNumberFormatter';

import { SelectorSwitcher } from '../SelectorSwitcher';
import { UpsellItem } from '../../contexts/upsellContext/upsell';
import { useUpsellInSelection } from '../upsellInSelectionContext';
import { PriceWithBreakLine } from '../../common/PriceWithBreakLine';
import { PriceText, PriceWrapper } from '../../common/ItemCard/StyledComponents';
import { AddButton, ModifyButton } from '../../common/ItemCard/Buttons';
import { useUpsell } from '../../contexts/upsellContext/upsellContext';

interface ItemUpsellPriceProps {
  item: UpsellItem;
}

export const NoDateRestriction = ({ item }: ItemUpsellPriceProps) => {
  const { price, totalQuantity, companyInformation, currency } = item;
  const { setIsDrawerOpen, setUpsellInModal } = useUpsellInSelection();
  const { setIsInitialDate, setIsInitialTime } = useUpsell();
  const { formatPrice } = useNumberFormatter(companyInformation?.locale?.language);

  const itemPrice = price !== undefined ? formatPrice(price, currency) : '';

  const handleModal = () => {
    if (!item || !Object.values(item).length) return;

    setIsInitialDate(true);
    setIsInitialTime(true);
    setUpsellInModal(item);
    setIsDrawerOpen(true);
  };

  const FirstElement = () => <AddButton onClick={handleModal} />;

  const SecondElement = () => <ModifyButton onClick={handleModal} />;

  return (
    <PriceWrapper>
      <PriceText variant="h3" onClick={handleModal} sx={{ cursor: 'pointer' }}>
        <PriceWithBreakLine price={itemPrice} />
      </PriceText>
      <SelectorSwitcher
        quantity={totalQuantity}
        FirstElement={FirstElement}
        SecondElement={SecondElement}
      />
    </PriceWrapper>
  );
};
