import { QuantitySelector } from '@guest-widgets/shared/src/components/QuantitySelector/QuantitySelector';
import { useNumberFormatter } from '@guest-widgets/shared/src/hooks/useNumberFormatter';

import { SelectorSwitcher } from '../SelectorSwitcher';
import { UpsellItem } from '../../contexts/upsellContext/upsell';
import { useUpsellInSelection } from '../upsellInSelectionContext';
import { PriceWithBreakLine } from '../../common/PriceWithBreakLine';
import { PriceText, PriceWrapper } from '../../common/ItemCard/StyledComponents';
import { AddButton } from '../../common/ItemCard/Buttons';
import { useInnerUpsell } from '../../hooks/upsell/useInnerUpsell';
import { useRemoveFromCart } from '../../hooks/useRemoveFromCart';
import { useUpsellMapper } from '../../hooks/upsell/useUpsellMapper';
import { useUpsell } from '../../contexts/upsellContext/upsellContext';

interface ItemUpsellPriceProps {
  item: UpsellItem;
}

export const SameParentDate = ({ item }: ItemUpsellPriceProps) => {
  const { price, totalQuantity, companyInformation, currency } = item;
  const { setIsInitialDate, setIsInitialTime } = useUpsell();
  const { handleQuantityChange, setIsDrawerOpen, setUpsellInModal } = useUpsellInSelection();
  const { addToCartHandler } = useInnerUpsell();
  const { mapUpsellToCartItem } = useUpsellMapper();
  const { onDeleteCartItem } = useRemoveFromCart({ item: mapUpsellToCartItem(item) });
  const { formatPrice } = useNumberFormatter(companyInformation?.locale?.language);
  const itemPrice = price !== undefined ? formatPrice(price, currency) : '';

  const handleModal = () => {
    if (!item || !Object.values(item).length) return;

    setIsInitialDate(true);
    setIsInitialTime(true);
    setUpsellInModal(item);
    setIsDrawerOpen(true);
  };

  const onQuantityChange = (qty: number) => {
    const quantity = { [item.guestTypes[0].id]: qty };
    const updatedUpsell = handleQuantityChange({ upsell: item, quantity });
    qty ? addToCartHandler(updatedUpsell) : onDeleteCartItem();
  };

  const FirstElement = () => <AddButton onClick={() => onQuantityChange(1)} />;

  const SecondElement = () => (
    <QuantitySelector
      quantity={totalQuantity}
      handleQuantityChange={(qty) => onQuantityChange(qty)}
    />
  );

  return (
    <PriceWrapper>
      <PriceText variant="h3" onClick={handleModal} sx={{ cursor: 'pointer' }}>
        <PriceWithBreakLine price={itemPrice} />
      </PriceText>
      <SelectorSwitcher
        quantity={totalQuantity}
        FirstElement={FirstElement}
        SecondElement={SecondElement}
      />
    </PriceWrapper>
  );
};
