import { Box, Typography } from '@mui/material';
import { styled } from '@guest-widgets/core';

import { useUpsellInSelection } from '../upsellInSelectionContext';

import { ImageWithFallback } from './ImageWithFallback';
import { DateTimeQty } from './DateTimeQty';

export const DateTimeLayout = () => {
  const { upsellInModal } = useUpsellInSelection();
  const { images, name, information } = upsellInModal;

  if (!Object.values(upsellInModal).length) return null;

  const { body } = information?.description || {};
  return (
    <Content>
      <Main>
        <Box mb={4}>
          <ImageWithFallback src={images?.[0]?.url ?? ''} alt={images?.[0]?.alt} />
        </Box>
        <Typography variant="h3" pb={4}>
          {name}
        </Typography>
        {body && <Description variant="body1" pb={4} dangerouslySetInnerHTML={{ __html: body }} />}
      </Main>
      <Aside>
        <Box mb={4}>
          <DateTimeQty item={upsellInModal} />
        </Box>
      </Aside>
    </Content>
  );
};

const Description = styled(Typography)({
  '& p': {
    marginBlockStart: 'unset',
    marginBlockEnd: 'unset',
  },
});

const Content = styled('div')({
  display: 'flex',
  gap: 16,
  marginTop: -40,
});

const Main = styled('div')({
  width: '66%',
  maxWidth: 'calc(100% - 320px)',
});

const Aside = styled('div')({
  width: '34%',
  minWidth: 320,
  position: 'sticky',
  bottom: 0,
  zIndex: 1,
});
