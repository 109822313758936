import { styled } from '@guest-widgets/core';
import { Typography } from '@mui/material';
import { QuantitySelector } from '@guest-widgets/shared/src/components/QuantitySelector/QuantitySelector';

import { PriceWithBreakLine } from '../../common/PriceWithBreakLine';
import { useUpsellInSelection } from '../upsellInSelectionContext';
import { UpsellItem } from '../../contexts/upsellContext/upsell';
import { Quantity } from '../../contexts/apiContext/product/dtos/request';

interface SimpleQtyProps {
  item: UpsellItem;
  itemPrice: string;
}

export const SimpleQty = ({ item, itemPrice }: SimpleQtyProps) => {
  const { handleQuantityChange } = useUpsellInSelection();
  const { totalQuantity } = item;

  const onQuantityChange = (qty: number) => {
    const quantity: Quantity = { [item.guestTypes[0].id]: qty };
    handleQuantityChange({ upsell: item, quantity, isModal: true });
  };

  return (
    <PriceWrapper>
      <Typography variant="body2" mr={2}>
        <PriceWithBreakLine price={itemPrice} />
      </Typography>
      <QuantitySelector
        quantity={totalQuantity}
        handleQuantityChange={(qty) => onQuantityChange(qty)}
      />
    </PriceWrapper>
  );
};

export const PriceWrapper = styled('div')(({ theme }) => ({
  width: '100%',
  paddingBottom: theme.spacing(4),
  display: 'flex',
  justifyContent: 'end',
  alignItems: 'center',
}));
