import { useEffect } from 'react';
import { isSameDay, subDays } from 'date-fns';

import { ProductProp } from '../../Product/Guest/productInSelectionContext';
import { UpsellItem } from '../../contexts/upsellContext/upsell';
import { useUpsell } from '../../contexts/upsellContext/upsellContext';
import { useTimeslots } from '../../contexts/timeslotsContext/timeslotsContext';
import { mapToQuantity } from '../../contexts/apiContext/product/mapToPricing';
import { TimeslotDayRange } from '../../Product/Steps/Day/Calendar/CalendarRange/useCalendarRange';
import { TimeRange } from '../../Upsell/upsellInSelectionContext';
import { Quantity } from '../../contexts/apiContext/product/dtos/request';

interface DateTimeQtyProps {
  upsell: ProductProp;
  time?: TimeRange;
  requiresTimeSelection: boolean;
  setDateHandler: (date?: TimeslotDayRange) => void;
  setTimeHandler: (time?: TimeRange) => void;
  setQuantity: (quantity: Quantity) => void;
}

/** Sets previous date and time when upsell is rendered in modal */
export const useUpsellItemsFromCart = ({
  upsell,
  time,
  requiresTimeSelection,
  setDateHandler,
  setTimeHandler,
  setQuantity,
}: DateTimeQtyProps) => {
  const { isInitialDate, setIsInitialDate, isInitialTime, setIsInitialTime } = useUpsell();
  const { timeslot } = useTimeslots();

  const idAndParentId = isUpsellItem(upsell) ? [upsell.id, upsell.parent.lineId] : [];
  const initialStateDependency = [
    ...idAndParentId,
    time?.startTime?.valueOf(),
    isInitialDate,
    isInitialTime,
  ];

  useEffect(() => {
    if (!isUpsellItem(upsell) || !upsell.start || !upsell.end) return;

    const { start, end, allocationType, guestTypes } = upsell;
    const dateStart = new Date(start);
    const dateEnd = new Date(end);
    const startSelected = timeslot.data?.days.find(({ day }) => isSameDay(day, dateStart));

    if (isInitialDate && startSelected) {
      const fixedEndDayByType = allocationType === 'day' ? subDays(dateEnd, 1) : dateEnd;
      const endSelected = !isSameDay(dateStart, dateEnd)
        ? timeslot.data?.days.find(({ day }) => isSameDay(day, fixedEndDayByType))
        : undefined;
      setDateHandler({ from: startSelected, to: endSelected });
      setIsInitialDate(false);

      // If upsell requires time selection, quantity will be set in isInitialTime condition
      if (requiresTimeSelection) return;

      setQuantity(mapToQuantity(guestTypes));
    }

    if (isInitialTime && time?.startTime && isSameDay(time?.startTime, dateStart)) {
      setTimeHandler({ startTime: dateStart, endTime: dateEnd });
      setQuantity(mapToQuantity(guestTypes));
      setIsInitialTime(false);
    }
  }, [...initialStateDependency]);
};

const isUpsellItem = (product: ProductProp): product is UpsellItem => {
  return (product as UpsellItem).parent !== undefined;
};
