import { Text } from '@eo-locale/react';
import { Box, Typography } from '@mui/material';
import { useNumberFormatter } from '@guest-widgets/shared/src/hooks/useNumberFormatter';

import ItemDeleteDialog from '../../common/ItemDeleteDialog';
import { UpsellItem } from '../../contexts/upsellContext/upsell';
import { PriceText, PriceWrapper } from '../../common/ItemCard/StyledComponents';
import { DeleteButton } from '../../common/ItemCard/Buttons';
import { useUpsellMapper } from '../../hooks/upsell/useUpsellMapper';
import { useProduct } from '../../contexts/productContext/productContext';
import { CartItemPrice } from '../../contexts/apiContext/cart/cart';
import { useRemoveFromCart } from '../../hooks/useRemoveFromCart';

interface ItemUpsellPriceProps {
  item: UpsellItem;
}

export const UpsellInCart = ({ item }: ItemUpsellPriceProps) => {
  const { currency, companyInformation } = useProduct();
  const { price, totalQuantity } = item;
  const { formatPrice } = useNumberFormatter(companyInformation?.locale?.language);
  const { mapUpsellToCartItem } = useUpsellMapper();
  const {
    openRemoveDialog,
    onCloseRemoveDialog,
    onDeleteCartItem,
    onOpenRemoveDialog,
    rateLimitError,
  } = useRemoveFromCart({
    item: mapUpsellToCartItem(item),
  });

  const cartItemPrice = (price as unknown) as CartItemPrice;
  const itemPrice = cartItemPrice.subtotal
    ? formatPrice(cartItemPrice.subtotal?.amount, currency)
    : '';

  return (
    <PriceWrapper>
      <Box display="flex" flexDirection="column">
        {!!totalQuantity && (
          <Typography variant="caption" sx={{ opacity: 0.6, marginBottom: 0.5 }}>
            <Text id="for-variable-guests" quantity={totalQuantity} />
          </Typography>
        )}
        <PriceText variant="h3">{itemPrice} </PriceText>
      </Box>
      <DeleteButton onClick={onOpenRemoveDialog} />
      <ItemDeleteDialog
        open={openRemoveDialog}
        onClose={onCloseRemoveDialog}
        onConfirm={onDeleteCartItem}
        productName={item?.name ?? ''}
        rateLimitError={rateLimitError}
      />
    </PriceWrapper>
  );
};
