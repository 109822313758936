import { SupportedCurrencies } from '../../settingsContext/settings';
import { UpsellPricing } from '../../upsellContext/upsell';
import { LineItem, ValidateResponse } from '../product/dtos/response';
import { isValidationNeeded, mapToError } from '../product/mapToPricing';

export const mapToUpsellsFromPricing = (
  response: ValidateResponse,
  currencyCode: SupportedCurrencies,
  validatedUpsellId: string
): UpsellPricing => {
  const { lineItems, isValid } = response?.data || {};
  const upsellItem = lineItems.find((upsell) => upsell.lineId === validatedUpsellId);

  if (!upsellItem) {
    return {
      upsellId: lineItems[0].lineId,
      totals: mapToTotals(lineItems[0], currencyCode),
      isValid,
    };
  }

  return {
    upsellId: upsellItem.lineId,
    totals: mapToTotals(upsellItem, currencyCode),
    error: isValidationNeeded(upsellItem.error?.context, upsellItem.guestTypes)
      ? mapToError(upsellItem.error)
      : undefined,
    isValid,
  };
};

const mapToTotals = ({ pricing }: LineItem, currencyCode: SupportedCurrencies) => ({
  subtotal: {
    amount: pricing ? pricing.subTotal / 100 : 0,
    currencyCode,
  },
  total: {
    amount: pricing ? pricing.total / 100 : 0,
    currencyCode,
  },
});
