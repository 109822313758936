import { styled } from '@guest-widgets/core';
import { Box, Typography } from '@mui/material';

export const PriceWrapper = styled(Box)({
  display: 'flex',
  alignSelf: 'end',
  justifyContent: 'space-between',
  alignItems: 'end',
});

export const PriceText = styled(Typography)({
  fontWeight: 700,
});
