import { CartItem } from '../../contexts/apiContext/cart/cart';
import { useCart } from '../../contexts/cartContext/cartContext';
import { useRouter } from '../../contexts/routerContext/routerContext';
import { DateRestriction, UpsellItem } from '../../contexts/upsellContext/upsell';

import { NoDateRestriction } from './NoDateRestriction';
import { SameParentDate } from './SameParentDate';
import { SameParentQuantity } from './SameParentQuantity';
import { UpsellInCart } from './UpsellInCart';

interface ItemUpsellPriceProps {
  item: UpsellItem;
}

export const ItemUpsellPrice = ({ item }: ItemUpsellPriceProps) => {
  const { currentStep } = useRouter();
  const { cartWithSteps } = useCart();
  const { items: cartItems } = cartWithSteps.data?.cart ?? {};
  const { sameParentQuantity } = item.configuration ?? getConfiguration(item, cartItems) ?? {};
  const isCartStep = currentStep === 'cart';
  const isNoDateRestriction = item.configuration?.dateRestriction === DateRestriction.UNRESTRICTED;

  if (isCartStep) {
    return <UpsellInCart item={item} />;
  }

  if (sameParentQuantity) {
    return <SameParentQuantity item={item} />;
  }

  if (isNoDateRestriction) {
    return <NoDateRestriction item={item} />;
  }

  return <SameParentDate item={item} />;
};

const getConfiguration = (upsellItem: UpsellItem, cartItems?: CartItem[]) => {
  return cartItems
    ?.find((cartItem) => cartItem.id.startsWith(`${upsellItem.id}.`))
    ?.upsells?.find((item) => item.id === upsellItem.id)?.configuration;
};
