import { useState, useEffect } from 'react';

import { isHtmlField, isTosField } from '../../contexts/contactContext/typeGuards';
import { useContact } from '../../contexts/contactContext/contactFormContext';
import { AdditionalFieldsType, BuyerAdditionalField } from '../../contexts/contactContext/contact';

function useExtractCustomerTosField() {
  const [customerTosField, setCustomerTosField] = useState<BuyerAdditionalField | null>(null);
  const [remainingData, setRemainingData] = useState<AdditionalFieldsType>([]);
  const { additionalFields } = useContact();
  function divideTosFieldsArray(data: BuyerAdditionalField) {
    const specifiedTypes: BuyerAdditionalField = [];
    const remainingItems: BuyerAdditionalField = [];

    data.forEach((field) => {
      if (isHtmlField(field) || isTosField(field)) {
        specifiedTypes.push(field);
      } else {
        remainingItems.push(field);
      }
    });

    return { tosFields: specifiedTypes, otherFields: remainingItems };
  }
  const extractCustomerTosField = () => {
    const remainingData: AdditionalFieldsType = [];

    additionalFields?.forEach((item) => {
      if (Array.isArray(item)) {
        const { tosFields, otherFields } = divideTosFieldsArray(item);
        setCustomerTosField(tosFields);
        remainingData.push(otherFields);
      } else if (typeof item === 'object' && item !== null) {
        remainingData.push(item);
      }
    });
    setRemainingData(remainingData);
  };
  useEffect(() => {
    extractCustomerTosField();
  }, [additionalFields?.length]);

  return { customerTosField, remainingData };
}

export default useExtractCustomerTosField;
