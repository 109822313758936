import { parseISO, isAfter, isBefore } from 'date-fns';

import { CartItem } from '../contexts/apiContext/cart/cart';
import { mapToRequestItems } from '../contexts/apiContext/product/mapToPricing';
import { Quantity } from '../contexts/apiContext/product/dtos/request';

export const useValidateApiItemGetter = () => {
  const getParentItem = (cartItems: CartItem[], parentLineId: string): CartItem | undefined => {
    const foundItem = cartItems.find((item) => item.id === parentLineId);
    if (!foundItem) return;
    return foundItem;
  };

  const getSimilarItems = (cartStateItems: CartItem[], experience: CartItem) => {
    const experienceStart = parseISO(experience.start);
    const experienceEnd = parseISO(experience.end);

    return cartStateItems.reduce((acc, item) => {
      if (item.product.productId !== experience.product.productId || item.id === experience.id)
        return acc;

      const itemStart = parseISO(item.start);
      const itemEnd = parseISO(item.end);

      if (isBefore(itemStart, experienceEnd) && isAfter(itemEnd, experienceStart)) {
        const isUpsell = item.id.includes('.');
        const parentLineId = item.id.split('.')[0];
        return isUpsell
          ? [...acc, { ...getParentItem(cartStateItems, parentLineId)! }, { ...item }]
          : [...acc, { ...item }];
      }
      return acc;
    }, [] as CartItem[]);
  };

  const getExperienceLineItems = (
    cartStateItems: CartItem[],
    experience: CartItem,
    lineId: string,
    quantity?: Quantity
  ) => {
    const { start, end, guestTypes, product } = experience;
    return [
      ...mapToRequestItems(getSimilarItems(cartStateItems, experience)),
      {
        lineId,
        productId: Number(product.productId),
        start,
        end,
        guestTypes: quantity ?? guestTypes,
      },
    ];
  };

  const getUpsellLineItems = (
    cartStateItems: CartItem[],
    upsells: CartItem[],
    quantity?: Quantity
  ) => {
    const parentLineId = upsells[0].id?.split('.')[0];

    const updatedUpsells = upsells.map((upsell) => {
      if (!quantity) return upsell;

      const updatedGuestTypes: Quantity = { ...upsell.guestTypes };
      Object.keys(updatedGuestTypes).forEach((key) => {
        if (quantity[key] !== undefined) {
          updatedGuestTypes[key] = quantity[key];
        }
      });

      return {
        ...upsell,
        guestTypes: updatedGuestTypes,
      };
    });

    return [
      ...mapToRequestItems([getParentItem(cartStateItems, parentLineId) ?? ({} as CartItem)]),
      ...mapToRequestItems(getSimilarItems(cartStateItems, updatedUpsells[0])),
      ...mapToRequestItems(updatedUpsells),
    ];
  };

  return { getExperienceLineItems, getUpsellLineItems };
};
