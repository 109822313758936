import { ImgHTMLAttributes, useState } from 'react';
import { styled } from '@guest-widgets/core';
import NoImageIcon from '@guest-widgets/shared/src/components/Icons/NoImageIcon';

interface ThumbWithFallbackProps extends ImgHTMLAttributes<HTMLImageElement> {
  src: string;
  noImageIconFontSize?: number;
}

export const ThumbWithFallback = ({ src, ...rest }: ThumbWithFallbackProps) => {
  return (
    <Container>
      <ThumbWithFallbackImage src={src} {...rest} />
    </Container>
  );
};

export const ThumbWithFallbackImage = ({
  src,
  noImageIconFontSize = 88,
  ...rest
}: ThumbWithFallbackProps) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  return (
    <>
      <ImagePlaceholder role="placeholder">
        <NoImageIcon sx={{ fontSize: noImageIconFontSize }} />
      </ImagePlaceholder>
      <Image
        src={src}
        {...rest}
        className={imageLoaded ? 'loaded' : ''}
        onLoad={() => setImageLoaded(true)}
      />
    </>
  );
};

const Container = styled('div')(({ theme: { spacing } }) => ({
  position: 'relative',
  width: spacing(24),
  height: spacing(24),
  borderRadius: spacing(1),
  overflow: 'hidden',
}));

const ImagePlaceholder = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  background: '#F1F5F9',
});

const Image = styled('img')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  opacity: 0,
  transition: 'opacity 0.3s ease-in-out',
  '&.loaded': {
    opacity: 1,
  },
});
