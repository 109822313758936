import { UseQueryResult, useQuery } from '@tanstack/react-query';

import { useSettings } from '../../settingsContext/settingsContext';
import { useSession } from '../cart/session/useSession';
import { CartItem } from '../cart/cart';
import { UpsellItem, UpsellPricing } from '../../upsellContext/upsell';
import { guestApi } from '../product/guestApi';
import { queryKeys } from '../../../queryKeys';
import { useValidateApiItemGetter } from '../../../hooks/useValidateApiItemGetter';

import { mapToUpsellsFromPricing } from './mapToUpsellsFromPricing';

interface UpsellApiProps {
  upsellsToValidate: CartItem[];
  upsells: UpsellItem[];
}

export const useUpsellApi = ({
  upsellsToValidate,
  upsells,
}: UpsellApiProps): UseQueryResult<UpsellPricing> => {
  const { locale, customerCode } = useSettings();
  const { cartState, setErrors } = useSession();
  const { getUpsellLineItems } = useValidateApiItemGetter();

  const { id, start, end, totalQuantity } = upsellsToValidate[0];

  const queryDeps = [queryKeys.UPSELL, id, totalQuantity, start, end];

  const hasParameters = Boolean(locale && customerCode && id && totalQuantity && start && end);

  const queryFn = async () => {
    const response = await guestApi.getPricing(customerCode, {
      lineItems: getUpsellLineItems(cartState.items, upsellsToValidate),
    });

    const { lineItems } = response.data;
    const error = lineItems.find((item) => item.error)?.error?.message;
    setErrors(error ? [error] : [], response.data.isValid);
    return mapToUpsellsFromPricing(response, upsells[0].currency, id);
  };

  return useQuery({
    queryKey: queryDeps,
    queryFn,
    enabled: hasParameters,
  });
};
