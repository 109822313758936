import { CartItem } from '../../contexts/apiContext/cart/cart';
import { Quantity } from '../../contexts/apiContext/product/dtos/request';
import { GuestType } from '../../contexts/productContext/product';
import { UpsellItem } from '../../contexts/upsellContext/upsell';

export const useUpsellMapper = () => {
  const mapCartItemToUpsell = (cartItem: CartItem): UpsellItem =>
    (({
      ...cartItem,
      ...cartItem.product,
      images: [cartItem.product.image],
    } as unknown) as UpsellItem);

  const mapUpsellToCartItem = (upsellItem: UpsellItem): CartItem =>
    (({
      ...upsellItem,
      product: {
        productId: upsellItem.productId,
        name: upsellItem.name,
        allocationType: upsellItem.allocationType,
        brand: upsellItem.companyInformation?.name,
        image: upsellItem.images?.[0],
      },
    } as unknown) as CartItem);

  const mapQuantityToGuestType = (quantity: Quantity, guestTypes: GuestType[]): GuestType[] => {
    const quantityMap = new Map(Object.entries(quantity));

    return guestTypes.map((guestType) => {
      const respectiveQuantity = quantityMap.get(guestType.id);
      return { ...guestType, quantity: respectiveQuantity ? Number(respectiveQuantity) : 0 };
    });
  };

  const mapGuestTypeToQuantity = (guestTypes: GuestType[]): Quantity => {
    const quantityMap = new Map();
    guestTypes.forEach((guestType) => quantityMap.set(guestType.id, guestType.quantity));

    return Object.fromEntries(quantityMap);
  };

  const mapGuestTypeToZero = (guestTypes: GuestType[]): Quantity => {
    const quantityMap = new Map();
    guestTypes.forEach((guestType) => quantityMap.set(guestType.id, 0));

    return Object.fromEntries(quantityMap);
  };

  return {
    mapCartItemToUpsell,
    mapUpsellToCartItem,
    mapQuantityToGuestType,
    mapGuestTypeToQuantity,
    mapGuestTypeToZero,
  };
};
