import { styled } from '@guest-widgets/core';
import { Box, Typography } from '@mui/material';
import { LineClamp } from '@guest-widgets/shared/src/components/LineClamp';

import { ItemCartPrice } from '../../Cart/Items/ItemCartPrice';
import { ItemUpsellPrice } from '../../Upsell/ItemUpsellPrice/ItemUpsellPrice';
import { UpsellItem } from '../../contexts/upsellContext/upsell';
import { CartItem } from '../../contexts/apiContext/cart/cart';
import { ThumbWithFallback } from '../ThumbWithFallback';
import { useUpsellInSelection } from '../../Upsell/upsellInSelectionContext';
import { useRouter } from '../../contexts/routerContext/routerContext';
import { useUpsell } from '../../contexts/upsellContext/upsellContext';
import { useInnerUpsell } from '../../hooks/upsell/useInnerUpsell';
import { ErrorMessage } from '../../Upsell/ErrorMessage';

export interface ItemContainerProps {
  cartItem?: CartItem;
  upsellItem?: UpsellItem;
}

export const InfoContainer = ({ cartItem, upsellItem }: ItemContainerProps) => {
  const { setUpsellInModal, setIsDrawerOpen, setIsModal, addToCartErrors } = useUpsellInSelection();
  const { setIsInitialDate, setIsInitialTime } = useUpsell();
  const { isSelectedUpsell } = useInnerUpsell();
  const { currentStep } = useRouter();
  const image = cartItem?.product.image ?? upsellItem?.images?.[0];
  const product = cartItem?.product ?? upsellItem;
  const isUpsellStep = currentStep === 'upsell';
  const error = upsellItem && isSelectedUpsell(upsellItem) ? addToCartErrors[0] : undefined;

  const handleModal = () => {
    if (!isUpsellStep || !upsellItem || !Object.values(upsellItem).length) return;

    setIsInitialDate(true);
    setIsInitialTime(true);
    setIsModal(true);
    setUpsellInModal(upsellItem);
    setIsDrawerOpen(true);
  };

  return (
    <>
      <Container>
        <ImageContainer onClick={handleModal} sx={{ cursor: isUpsellStep ? 'pointer' : 'default' }}>
          <ThumbWithFallback src={image?.thumbnailUrl ?? ''} alt={image?.alt} />
        </ImageContainer>
        <Box onClick={handleModal} sx={{ cursor: isUpsellStep ? 'pointer' : 'default' }}>
          <Typography variant="h4">
            <LineClamp lines={2}> {product?.name} </LineClamp>
          </Typography>
        </Box>
        {!!cartItem ? (
          <ItemCartPrice item={cartItem as CartItem} />
        ) : (
          <ItemUpsellPrice item={upsellItem as UpsellItem} />
        )}
      </Container>
      {!!error && <ErrorMessage message={error} />}
    </>
  );
};

const Container = styled('div')(({ theme: { spacing } }) => ({
  display: 'grid',
  gridTemplateColumns: '96px auto',
  gridTemplateRows: 'auto auto',
  columnGap: spacing(2),
  borderRadius: 8,
  height: 96,
  '&:before': {
    border: 'none',
  },
}));

const ImageContainer = styled(Box)({
  cursor: 'pointer',
  gridRowStart: 1,
  gridRowEnd: 'span 2',
  borderRadius: 4,
});
