import { useState } from 'react';
import { handleTrackingEvent } from '@guest-widgets/shared/src/utils/customerTracking/utils/trackingEventsHandler';
import { TrackingEventType } from '@guest-widgets/shared/src/utils/customerTracking/types';

import { useCart } from '../contexts/cartContext/cartContext';
import { CartItem } from '../contexts/apiContext/cart/cart';
import { useSession } from '../contexts/apiContext/cart/session/useSession';
import { useSettings } from '../contexts/settingsContext/settingsContext';
import { getItemSimilarityKey } from '../contexts/apiContext/cart/session/addToCart';

interface RemoveFromCartProps {
  item: CartItem;
}

export const useRemoveFromCart = ({ item }: RemoveFromCartProps) => {
  const [openRemoveDialog, setOpenRemoveDialog] = useState(false);
  const [rateLimitError, setRateLimitError] = useState(false);

  const { currency } = useSettings();
  const { removeItem } = useCart();
  const { cartState } = useSession();

  const similarItemInCart =
    cartState.items.find(
      (cartItem) => getItemSimilarityKey(cartItem) === getItemSimilarityKey(item)
    ) ?? item;

  const onOpenRemoveDialog = () => {
    setOpenRemoveDialog(true);
  };

  const onCloseRemoveDialog = () => {
    setOpenRemoveDialog(false);
  };

  const onDeleteCartItem = () => {
    removeItem
      .mutateAsync(similarItemInCart)
      .then(() => {
        onCloseRemoveDialog();

        const itemsToRemove = cartState.items.filter(
          (cartItem) =>
            cartItem.id === similarItemInCart.id ||
            cartItem.id.startsWith(`${similarItemInCart.id}.`)
        );
        itemsToRemove.forEach((item) => {
          handleTrackingEvent(TrackingEventType.REMOVE_FROM_CART, {
            removedItem: item,
            currency,
          });
        });
      })
      .catch((error) => {
        if (error.message.includes('429')) {
          setRateLimitError(true);
        }
      });
  };

  return {
    openRemoveDialog,
    onOpenRemoveDialog,
    onCloseRemoveDialog,
    onDeleteCartItem,
    rateLimitError,
  };
};
