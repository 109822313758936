import { PropsWithChildren, createContext, useContext, useEffect, useState } from 'react';
import { UseQueryResult } from '@tanstack/react-query';

import { useCart } from '../cartContext/cartContext';
import { CartItem, CartWithSteps } from '../apiContext/cart/cart';
import { useProduct } from '../productContext/productContext';
import { useUpsellMapper } from '../../hooks/upsell/useUpsellMapper';
import { generateUpsellItemId } from '../apiContext/cart/session/cartItemId';

import { UpsellItem } from './upsell';

interface UpsellContextType {
  upsells: UpsellItem[];
  cartStatus: UseQueryResult<CartWithSteps, unknown>;
  currentItem?: CartItem;
  hasUpsells: boolean;
  hasError: boolean;
  isInitialDate: boolean;
  isInitialTime: boolean;
  setUpsells: (upsells: UpsellItem[]) => void;
  setIsInitialDate: (isInitialDate: boolean) => void;
  setIsInitialTime: (isInitialTime: boolean) => void;
}

const upsellContext = createContext<UpsellContextType>({} as UpsellContextType);

export const UpsellContextProvider = ({ children }: PropsWithChildren<{}>) => {
  const [upsells, setUpsells] = useState<UpsellItem[]>([]);
  const [isInitialDate, setIsInitialDate] = useState<boolean>(true);
  const [isInitialTime, setIsInitialTime] = useState<boolean>(true);

  const { productId } = useProduct();
  const { cartWithSteps } = useCart();
  const { mapQuantityToGuestType } = useUpsellMapper();

  const currentItem = cartWithSteps.data?.cart?.items
    .filter((item) => item.product.productId === productId)
    .at(-1);
  const hasUpsells = !!currentItem?.upsells?.length;
  const hasError = upsells.some((upsell) => !!upsell.error && !!upsell.totalQuantity);
  const hasCartError = !!cartWithSteps.data?.cart?.errors.length;

  useEffect(() => {
    const areNewUpsells =
      upsells.every((upsell) => !upsell.id) || currentItem?.id !== upsells[0].parent.lineId;
    if (
      cartWithSteps.isSuccess &&
      !cartWithSteps.isFetching &&
      currentItem?.upsells &&
      (areNewUpsells || hasCartError)
    ) {
      const updatedUpsells = updateExistentUpsellFromCart(
        currentItem?.upsells,
        cartWithSteps.data?.cart?.items || []
      );
      setUpsells(updatedUpsells);
    }
  }, [cartWithSteps.isSuccess, cartWithSteps.isFetching]);

  const updateExistentUpsellFromCart = (
    upsells: UpsellItem[],
    cartItems: CartItem[]
  ): UpsellItem[] => {
    return upsells.map((upsell) => {
      if (!cartItems.length) return upsell;

      const upsellSimilarKey = JSON.stringify({
        parentLineId: upsell.parent.lineId,
        productId: upsell.productId,
      });

      const cartItem = cartItems.find((item) => {
        return getSimilarityKey(item) === upsellSimilarKey;
      });

      if (!cartItem) {
        const isSameParentDate = upsell.configuration.sameParentQuantity;
        return {
          ...upsell,
          id: upsell.id ?? generateUpsellItemId(upsell.parent.lineId),
          start: isSameParentDate ? upsell.parent.start : undefined,
          end: isSameParentDate ? upsell.parent.end : undefined,
        };
      }

      return {
        ...upsell,
        id: cartItem.id ?? generateUpsellItemId(upsell.parent.lineId),
        guestTypes: mapQuantityToGuestType(cartItem.guestTypes, upsell.guestTypes),
        start: cartItem.start,
        end: cartItem.end,
        totalQuantity: cartItem.totalQuantity,
      };
    });
  };

  const value = {
    upsells,
    cartStatus: cartWithSteps,
    currentItem,
    hasUpsells,
    hasError,
    isInitialDate,
    isInitialTime,
    setUpsells,
    setIsInitialDate,
    setIsInitialTime,
  };

  return <upsellContext.Provider value={value}>{children}</upsellContext.Provider>;
};

export const useUpsell = () => useContext(upsellContext);

const getSimilarityKey = ({ id, product }: CartItem): string => {
  const parentLineId = id?.includes('.') ? id.split('.')[0] : '';
  return JSON.stringify({
    parentLineId,
    productId: product.productId,
  });
};
