import { styled } from '@guest-widgets/core';
import { IconButton, Drawer as MuiDrawer, Theme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { useUpsellInSelection } from '../upsellInSelectionContext';
import { useUpsell } from '../../contexts/upsellContext/upsellContext';
import ItemDeleteDialog from '../../common/ItemDeleteDialog';

import { SimpleLayout } from './SimpleLayout';
import { DateTimeLayout } from './DateTimeLayout';
import { Navigation } from './Navigation';
import { useInnerDrawer } from './useInnerDrawer';

interface MobileDrawerProps {
  open: boolean;
}

export const DesktopDrawer = ({ open }: MobileDrawerProps) => {
  const { hasError } = useUpsell();
  const { upsellInModal, setIsDrawerOpen, addToCartErrors } = useUpsellInSelection();
  const {
    withCalendar,
    rateLimitError,
    removeFromCart,
    addToCart,
    openRemoveDialog,
    onCloseRemoveDialog,
    onOpenRemoveDialog,
  } = useInnerDrawer();

  const { totalQuantity } = upsellInModal;

  return (
    <Drawer open={open} anchor="bottom" showCalendar={withCalendar} disableEnforceFocus>
      <CloseButton onClick={() => setIsDrawerOpen(false)}>
        <CloseIcon />
      </CloseButton>
      {withCalendar ? <DateTimeLayout /> : <SimpleLayout />}
      <Navigation
        parentErrors={addToCartErrors}
        disabled={hasError || !totalQuantity}
        rateLimitError={rateLimitError}
        showRemoveButton={!!totalQuantity}
        onAddToCart={addToCart}
        onRemoveFromCart={onOpenRemoveDialog}
      />
      <ItemDeleteDialog
        open={openRemoveDialog}
        onClose={onCloseRemoveDialog}
        onConfirm={removeFromCart}
        productName={upsellInModal.name}
        rateLimitError={rateLimitError}
      />
    </Drawer>
  );
};

const PAPER_CORNER_RADIUS = 16;

interface DrawerProps {
  showCalendar: boolean;
  theme: Theme;
}

const Drawer = styled(({ showCalendar, ...props }) => <MuiDrawer {...props} />)(
  ({ showCalendar, theme: { spacing, breakpoints } }: DrawerProps) => ({
    display: 'flex',
    position: 'relative',
    '& [class*="MuiDrawer-paper"]': {
      padding: spacing(4, 4, 0),
      height: `calc(100% - ${spacing(42)})`,
      margin: 'auto',
      borderTopLeftRadius: PAPER_CORNER_RADIUS,
      borderTopRightRadius: PAPER_CORNER_RADIUS,
      [breakpoints.up('md')]: {
        width: '66%',
        maxWidth: showCalendar ? 1256 : 616,
        minWidth: 616,
      },
      [breakpoints.down('md')]: {
        width: 'unset',
        maxWidth: 'unset',
        minWidth: 'unset',
      },
    },
  })
);

const CloseButton = styled(IconButton)(({ theme: { palette, shadows } }) => ({
  position: 'sticky',
  zIndex: 1,
  width: 'fit-content',
  top: 8,
  left: 8,
  backgroundColor: palette.background.paper,
  boxShadow: shadows[4],
  transition: 'box-shadow ease 0.3s',
  '&:hover': {
    backgroundColor: palette.background.paper,
    boxShadow: 'unset',
  },
}));
