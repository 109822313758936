import { CartItem, CartState, CartWithSteps } from '../cart';
import { Quantity } from '../../product/dtos/request';
import { UpsellItem } from '../../../upsellContext/upsell';

import { refreshCartState } from './refreshCartState';
import { AddToCartResult } from './useSession';

interface SimilarItemChecker {
  similarItem?: CartItem;
  remainingItems: CartItem[];
}

interface AddToCartParams {
  cartState: CartState;
  newCartItems: CartItem[];
  currencyCode: string;
  customerCode: string;
  steps: CartWithSteps['steps'];
  discountCodes: string[];
}

export const addToCart = async ({
  cartState,
  newCartItems,
  currencyCode,
  customerCode,
  steps,
  discountCodes,
}: AddToCartParams): Promise<AddToCartResult> => {
  const items = newCartItems.reduce(
    (acc, newCartItem) => {
      const { similarItem, remainingItems } = handleSimilarItems(acc, newCartItem);
      return [...remainingItems, sumSimilarItems(newCartItem, similarItem)];
    },
    [...cartState.items] as CartItem[]
  );

  const newStateCandidate = {
    ...cartState,
    items,
    errors: [],
  };

  const newStateChecked = await refreshCartState(
    newStateCandidate,
    customerCode,
    currencyCode,
    discountCodes
  );

  const addedItemError = newStateChecked.items.find((item) => item.id === newCartItems[0].id)
    ?.error;

  const newState = addedItemError ? cartState : newStateChecked;

  return {
    cartWithSteps: {
      cart: { ...newState, errors: addedItemError ? [addedItemError] : [] },
      steps,
    },
    ...(addedItemError ? { addedItemError } : {}),
  };
};

export const getItemSimilarityKey = ({ id, product, start, end }: CartItem): string => {
  const parentLineId = id?.includes('.') ? id.split('.')[0] : '';
  return JSON.stringify({
    parentLineId,
    productId: product.productId,
    start,
    end,
  });
};

export const getUpsellSimilarityKey = ({ productId, parent, start, end }: UpsellItem): string => {
  return JSON.stringify({
    parentLineId: parent.lineId,
    productId,
    start,
    end,
  });
};

const handleSimilarItems = (
  cartStateItems: CartItem[],
  experience: CartItem
): SimilarItemChecker => {
  const isUpsell = experience.id.includes('.');

  return cartStateItems.reduce(
    (acc, item) => {
      const hasSameContent = getItemSimilarityKey(item) === getItemSimilarityKey(experience);
      const isSameUpsell = isUpsell ? item.id === experience.id : false;

      if (hasSameContent || isSameUpsell) {
        return { ...acc, similarItem: item };
      }

      return { ...acc, remainingItems: [...acc.remainingItems, item] };
    },
    { similarItem: undefined, remainingItems: [] } as SimilarItemChecker
  );
};

const sumSimilarItems = (experience: CartItem, similarItem?: CartItem): CartItem => {
  const isUpsell = experience.id.includes('.');

  if (!similarItem || isUpsell) return experience;

  const guestTypesEntries = [
    ...Object.entries(similarItem.guestTypes),
    ...Object.entries(experience.guestTypes),
  ];
  const guestTypes = guestTypesEntries.reduce((acc, [key, value]) => {
    // Upsells have same values that were in the cart + new added values
    acc[key] = isUpsell ? value : (acc[key] || 0) + value;
    return acc;
  }, {} as Quantity);

  return { ...similarItem, guestTypes };
};
