import { useEffect } from 'react';
import { handleTrackingEvent } from '@guest-widgets/shared/src/utils/customerTracking/utils/trackingEventsHandler';
import { TrackingEventType } from '@guest-widgets/shared/src/utils/customerTracking/types';

import { useProduct } from '../contexts/productContext/productContext';
import { useSettings } from '../contexts/settingsContext/settingsContext';
import { useRouter } from '../contexts/routerContext/routerContext';

export const useTrackingProductInfo = () => {
  const { currentStep } = useRouter();
  const product = useProduct();
  const settings = useSettings();

  useEffect(() => {
    const productVaribales = `${product.productId}-${settings.productId}-${currentStep}`;
    if (product.viewedProduct === productVaribales) {
      return;
    }

    if (currentStep === 'product' && product && settings && !settings.isLoading) {
      product.setViewedProduct(productVaribales);
      handleTrackingEvent(TrackingEventType.VIEW_PRODUCT, { product, settings });
    }
  }, [product.productId, settings.productId, currentStep]);
};
