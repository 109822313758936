import { Button } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Text } from '@eo-locale/react';
import { styled } from '@guest-widgets/core';
import { booking } from '@guest-widgets/shared/src/classes/booking';
import { ICON_BASE_SIZE, LoadingButton } from '@guest-widgets/shared/src/components/LoadingButton';

import { NavigationContainer, StyledErrorContainer } from '../StyledSharedComponents';
import { useRouter } from '../contexts/routerContext/routerContext';
import { ActionStatus, ApiInvalidStructureException } from '../contexts/apiContext/apiContext';
import { ErrorMessage } from '../Error';

export interface NavigationProps {
  actionStatus: ActionStatus[];
  parentErrors?: string[];
  disabled: boolean;
  onAddToCart?: () => void;

  rateLimitError?: boolean;
}

export const Navigation = ({
  actionStatus,
  disabled,
  parentErrors,
  rateLimitError,
}: NavigationProps) => {
  const { previousStep, nextStep } = useRouter();
  const [cartStatus, upsellStatus] = actionStatus;

  const renderErrors = () => {
    if (rateLimitError)
      return <ErrorMessage show messageKey={'rate-limited-error'} mt={0} mb={0} />;
    if (parentErrors?.length)
      return parentErrors.map((error) => (
        <ErrorMessage key={error} show={!!error} message={error} mt={0} mb={0} />
      ));
    if (cartStatus.error instanceof ApiInvalidStructureException)
      return <ErrorMessage show={cartStatus.isError} message={cartStatus.error.message} mt={0} />;
    return <ErrorMessage show={cartStatus.isError} messageKey="server-unreachable" mt={0} />;
  };

  return (
    <FloatingButtons className={booking.navigation.root}>
      <StyledErrorContainer>{renderErrors()}</StyledErrorContainer>
      <LoadingButton
        loading={cartStatus.isLoading || upsellStatus.isFetching!}
        variant="contained"
        color="primary"
        size="large"
        iconSize={ICON_BASE_SIZE}
        fullWidth
        onClick={nextStep}
        disabled={disabled}
        className={`${booking.buttons.button} ${booking.buttons.upsell}`}
      >
        <Text id="next-contact-details" />
      </LoadingButton>
      <Button
        variant="outlined"
        color="primary"
        startIcon={<ChevronLeftIcon />}
        onClick={previousStep}
        fullWidth
        className={booking.buttons.back}
      >
        <Text id="back" />
      </Button>
    </FloatingButtons>
  );
};

const MARGIN = 16;
const BACK_BUTTON_HEIGHT = 42;

const FloatingButtons = styled(NavigationContainer)({
  position: 'sticky',
  bottom: (BACK_BUTTON_HEIGHT + MARGIN) * -1,
  left: 0,
  right: 0,
  background: 'linear-gradient(transparent 25%, #FFFFFF 60%)',
  zIndex: 1,
});
