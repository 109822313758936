import React, { useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useWidget } from '@guest-widgets/core';
import uniqueId from 'lodash/uniqueId';
import { styled } from '@mui/material/styles';
import { getOutterShadowHost } from '@guest-widgets/shared/src/utils/getOutterShadowHost';
import { handleTrackingEvent } from '@guest-widgets/shared/src/utils/customerTracking/utils/trackingEventsHandler';
import { TrackingEventType } from '@guest-widgets/shared/src/utils/customerTracking/types';

import { Stepper } from '../../Stepper';
import { StyledContainer } from '../../StyledSharedComponents';
import { Totals } from '../../common/Totals';
import { usePayment } from '../../hooks/cart/usePayment';
import { useCart } from '../../contexts/cartContext/cartContext';

import { Navigation } from './Navigation';
import {
  addNppCheckoutScript,
  NppCheckoutElement,
  NppSuccessEvent,
  NppRedirectEvent,
  NppFailedEvent,
  NppEventName,
} from './nppCheckout';

declare global {
  interface Window {
    getNppCheckoutConfiguration?: () => void;
  }
}

export interface PaymentProps {
  paymentId: string;
  successHandler: () => void;
  redirectHandler: (url: string) => void;
  failureHandler: () => void;
}

export const Payment = ({
  paymentId,
  successHandler,
  failureHandler,
  redirectHandler,
}: PaymentProps) => {
  const { containerElement } = useWidget();
  const [paymentInProgress, setPaymentInProgress] = useState(false);
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (!('getNppCheckoutConfiguration' in window)) {
      window.getNppCheckoutConfiguration = () => ({
        payButton: {
          hide: true,
        },
        css: {
          paymentTotalContainer: {
            display: 'none',
          },
        },
      });
    }

    addNppCheckoutScript(() => setInitialized(true));

    document.addEventListener(NppEventName.PAYMENT_SUCCESS, onPaymentSuccess);
    document.addEventListener(NppEventName.PAYMENT_REDIRECT, onPaymentRedirect);
    document.addEventListener(NppEventName.PAYMENT_FAILED, onPaymentFailure);

    //TODO: This event present in documentation and examples, but isn't reachable when testing failed payments
    document.addEventListener(NppEventName.CUSTOMER_VALIDATION_FAILURE, onPaymentFailure);

    return () => {
      document.removeEventListener(NppEventName.PAYMENT_SUCCESS, onPaymentSuccess);
      document.removeEventListener(NppEventName.PAYMENT_REDIRECT, onPaymentRedirect);
      document.removeEventListener(NppEventName.PAYMENT_FAILED, onPaymentFailure);
      document.removeEventListener(NppEventName.CUSTOMER_VALIDATION_FAILURE, onPaymentFailure);
    };
  }, []);

  //TODO: event npp-payment-failed is not fired in some cases
  // So we should unblock pay button in any case after some period of time
  // Check and remove this once https://checkfront.atlassian.net/browse/TMT-1660 is resolved
  const paymentProgressTimeoutId = useRef(0);
  useEffect(() => {
    if (paymentProgressTimeoutId.current !== 0) {
      window.clearTimeout(paymentProgressTimeoutId.current);
      paymentProgressTimeoutId.current = 0;
    }
    if (paymentInProgress) {
      paymentProgressTimeoutId.current = window.setTimeout(
        () => setPaymentInProgress(false),
        10000
      );
    }
  }, [paymentInProgress]);

  const { priceToPay } = usePayment();

  const { cartWithSteps } = useCart();

  const outerShadowHost = useMemo<Node>(() => getOutterShadowHost(containerElement), [
    containerElement,
  ]);

  const onPaymentSuccess: EventListener = (e) => {
    if ((e as NppSuccessEvent).detail.paymentRequesterReference !== paymentId) {
      return;
    }
    successHandler();
  };

  const onPaymentRedirect: EventListener = (e) => {
    if ((e as NppRedirectEvent).detail.paymentRequesterReference !== paymentId) {
      return;
    }
    redirectHandler((e as NppRedirectEvent).detail.redirectLink);
  };

  const onPaymentFailure: EventListener = (e) => {
    if ((e as NppFailedEvent).detail.paymentRequesterReference !== paymentId) {
      return;
    }
    setPaymentInProgress(false);
    //TODO: It is unclear if we should re-create booking and payment reference on error
    // For now payments can be re-tried with same payment reference, so we should not switch to failed screen
    //failureHandler();
  };

  const onPayButtonClick = () => {
    const checkoutElement = (outerShadowHost as Element).getElementsByTagName('npp-checkout')?.[0];
    if (checkoutElement) {
      setPaymentInProgress(true);
      handleTrackingEvent(TrackingEventType.CHECKOUT, {
        items: cartWithSteps?.data?.cart?.items || [],
        currency: priceToPay.currencyCode,
      });
      (checkoutElement as NppCheckoutElement).pay();
    }
  };

  const slotName = uniqueId('npp-checkout');

  return (
    <div>
      <Stepper />
      {initialized && (
        <Container>
          {createPortal(
            <div slot={slotName}>
              <npp-checkout
                paymentRequesterReference={paymentId}
                checkoutConfiguration="getNppCheckoutConfiguration"
              ></npp-checkout>
            </div>,
            outerShadowHost as Element
          )}
          <slot name={slotName}></slot>
        </Container>
      )}
      <StyledHr />
      <Totals />
      <Navigation
        action={onPayButtonClick}
        paymentInProgress={paymentInProgress}
        priceToPay={priceToPay}
      />
    </div>
  );
};

const StyledHr = styled('div')(({ theme: { border, spacing } }) => ({
  borderBottom: border,
  marginBottom: spacing(2),
  marginTop: spacing(-2),
}));

const Container = styled(StyledContainer)(({ theme: { spacing } }) => ({
  marginTop: spacing(2),
}));
