import { Text } from '@eo-locale/react';
import { Box, Typography } from '@mui/material';
import { useNumberFormatter } from '@guest-widgets/shared/src/hooks/useNumberFormatter';

import { SelectorSwitcher } from '../SelectorSwitcher';
import ItemDeleteDialog from '../../common/ItemDeleteDialog';
import { UpsellItem } from '../../contexts/upsellContext/upsell';
import { useUpsellInSelection } from '../upsellInSelectionContext';
import { PriceText, PriceWrapper } from '../../common/ItemCard/StyledComponents';
import { AddButton, DeleteButton } from '../../common/ItemCard/Buttons';
import { useUpsellMapper } from '../../hooks/upsell/useUpsellMapper';
import { useRemoveFromCart } from '../../hooks/useRemoveFromCart';
import { useInnerUpsell } from '../../hooks/upsell/useInnerUpsell';
import { useCart } from '../../contexts/cartContext/cartContext';

interface ItemUpsellPriceProps {
  item: UpsellItem;
}

export const SameParentQuantity = ({ item }: ItemUpsellPriceProps) => {
  const { price, totalQuantity, currency, companyInformation, name } = item;
  const { handleQuantityChange, setIsDrawerOpen, setUpsellInModal } = useUpsellInSelection();
  const { addToCartHandler } = useInnerUpsell();
  const { cartWithSteps } = useCart();
  const { mapUpsellToCartItem, mapGuestTypeToZero } = useUpsellMapper();
  const {
    openRemoveDialog,
    onCloseRemoveDialog,
    onDeleteCartItem,
    onOpenRemoveDialog,
  } = useRemoveFromCart({
    item: mapUpsellToCartItem(item),
  });

  const cartItem = cartWithSteps.data?.cart.items.find(
    (itemInCart) => itemInCart.id === item.parent.lineId
  );
  const parentQuantity = cartItem?.guestTypes;
  const { formatPrice } = useNumberFormatter(companyInformation?.locale?.language);
  const itemPrice = price !== undefined ? formatPrice(price, currency) : '';

  const removeFromCart = () => {
    handleQuantityChange({
      upsell: item,
      quantity: mapGuestTypeToZero(item.guestTypes),
    });
    onDeleteCartItem();
    setIsDrawerOpen(false);
  };

  const addToCart = () => {
    handleQuantityChange({
      upsell: item,
      quantity: parentQuantity!,
    });
    addToCartHandler(item);
  };

  const handleModal = () => {
    if (!item || !Object.values(item).length) return;

    setUpsellInModal(item);
    setIsDrawerOpen(true);
  };

  const FirstElement = () => <AddButton onClick={addToCart} />;

  const SecondElement = () => (
    <>
      <DeleteButton onClick={onOpenRemoveDialog} />
      <ItemDeleteDialog
        open={openRemoveDialog}
        onClose={onCloseRemoveDialog}
        onConfirm={removeFromCart}
        productName={name ?? ''}
      />
    </>
  );

  return (
    <PriceWrapper>
      <Box display="flex" flexDirection="column" onClick={handleModal} sx={{ cursor: 'pointer' }}>
        {!!totalQuantity && (
          <Typography variant="caption" sx={{ opacity: 0.6, marginBottom: 0.5 }}>
            <Text id="for-variable-guests" quantity={Object.values(parentQuantity!)[0]} />
          </Typography>
        )}
        <PriceText variant="h3">{itemPrice} </PriceText>
      </Box>
      <SelectorSwitcher
        quantity={totalQuantity}
        FirstElement={FirstElement}
        SecondElement={SecondElement}
      />
    </PriceWrapper>
  );
};
