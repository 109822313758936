import { ImageDto } from '@checkfront/guest-experience-api-api-client-javascript-axios';
import { customerTracking } from '@guest-widgets/shared/src/utils/customerTracking/customerTracking';
import { TrackingEventType } from '@guest-widgets/shared/src/utils/customerTracking/types';
import { handleTrackingEvent } from '@guest-widgets/shared/src/utils/customerTracking/utils/trackingEventsHandler';

import { useCart } from '../../contexts/cartContext/cartContext';
import { CartItem } from '../../contexts/apiContext/cart/cart';
import { UpsellItem } from '../../contexts/upsellContext/upsell';
import { useUpsellInSelection } from '../../Upsell/upsellInSelectionContext';
import { getItemSimilarityKey } from '../../contexts/apiContext/cart/session/addToCart';
import { useSession } from '../../contexts/apiContext/cart/session/useSession';
import { mapToQuantity } from '../../contexts/apiContext/product/mapToPricing';

export const useInnerUpsell = () => {
  const { addToCart, cartWithSteps } = useCart();
  const { generateUpsellId } = useSession();
  const { upsellItem, setIsDrawerOpen, setIsModal, setAddToCartErrors } = useUpsellInSelection();

  const isLoading = addToCart.isLoading || cartWithSteps.isFetching;
  const isSelectedUpsell = (item: UpsellItem) => item.productId === upsellItem?.productId;

  const addToCartHandler = (upsellItem: UpsellItem) => {
    const obj: CartItem = createCartItem(upsellItem);

    addToCart.mutateAsync([obj]).then(({ cartWithSteps, addedItemError }) => {
      setAddToCartErrors(addedItemError ? [addedItemError] : []);

      // Finishes the upsell drawer flow if the user has added an upsell with quantity
      if (!addedItemError) {
        setIsModal(false);
        setIsDrawerOpen(false);
        addToCart.reset();
        trackAddToCart(cartWithSteps.cart.items, obj);
      }
    });
  };

  const trackAddToCart = (items: CartItem[], upsell: CartItem) => {
    const targetedItem = items.find(
      (item) => getItemSimilarityKey(item) === getItemSimilarityKey(upsell)
    );

    const itemToSend = { ...targetedItem!, guestTypes: upsell.guestTypes };

    handleTrackingEvent(TrackingEventType.ADD_TO_CART, {
      addedItem: itemToSend,
      currency: upsellItem.currency,
    });
  };

  const createCartItem = (upsell: UpsellItem): CartItem => ({
    id: upsell.id ?? generateUpsellId(upsell.parent.lineId),
    product: {
      name: upsell.name,
      productId: upsell.productId,
      allocationType: upsell.allocationType,
      brand: upsell.companyInformation?.name || '',
      image: upsell.images?.[0] || ({} as ImageDto),
    },
    start: upsell.start || '',
    end: upsell.end || '',
    guestTypes: mapToQuantity(upsell.guestTypes),
    price: {
      total: {
        amount: upsell.price || 0,
        currencyCode: upsell.currency,
      },
    },
    totalQuantity: upsell.totalQuantity,
  });

  return {
    addToCartHandler,
    isSelectedUpsell,
    isLoading,
    upsellStatus: cartWithSteps,
    cartStatus: addToCart,
    createCartItem,
  };
};
