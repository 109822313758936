import { Quantity } from '../apiContext/product/dtos/request';
import { PricingError } from '../apiContext/product/product';
import { PublicMoney } from '../cartContext/cart';
import { GuestProduct } from '../productContext/product';

export interface UpsellPricing {
  upsellId: string;
  totals: TotalPrice;
  error?: PricingError;
  isValid: boolean;
}

interface TotalPrice {
  subtotal: PublicMoney;
  total: PublicMoney;
}

export interface UpsellItem extends GuestProduct {
  id?: string;
  parent: {
    productId: string;
    lineId: string;
    start: string;
    end: string;
  };
  configuration: {
    attachmentType: string;
    dateRestriction: DateRestriction;
    sameParentQuantity: boolean;
    allowTimeAdjustment: boolean;
  };
  start?: string;
  end?: string;
  totalQuantity: number;
  error?: PricingError;
}

export interface UpsellParent {
  productId: string;
  cartId: string;
}

export interface UpsellToValidate {
  parent: {
    lineId: string;
  };
  product: {
    productId: number;
  };
  guestTypes?: Quantity;
  start?: string;
  end?: string;
}

export enum DateRestriction {
  PARENT_DATES = 'useParentDates',
  UNRESTRICTED = 'unrestricted',
  WITHIN_PARENT_DATES = 'selectWithinParentDates',
  CUSTOM = 'custom',
}
