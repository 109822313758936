import { CircularProgress, Grid } from '@mui/material';
import { booking } from '@guest-widgets/shared/src/classes/booking';

import { LoadingContainer } from '../../StyledSharedComponents';
import { useCart } from '../../contexts/cartContext/cartContext';
import { CartItem } from '../../contexts/apiContext/cart/cart';

import { Item } from './Item';

export const Items = () => {
  const { cartWithSteps, removeItem } = useCart();
  const cartItems = cartWithSteps.data?.cart?.items.filter((item) => !item.id.includes('.')) ?? [];

  const hasLoadingAction = removeItem.isLoading;
  const isRemovedItem = (item: CartItem) => removeItem.data?.cart.id === item.id.toString();

  return (
    <>
      <div className={booking.cart.table}>
        <Grid container spacing={4}>
          {cartItems.map((cartItem) => (
            <Grid item xs={12} key={cartItem.id} sx={{ position: 'relative' }}>
              <Item cartItem={cartItem} />
              {isRemovedItem(cartItem) && hasLoadingAction && (
                <LoadingContainer>
                  <CircularProgress size={50} />
                </LoadingContainer>
              )}
            </Grid>
          ))}
        </Grid>
      </div>
    </>
  );
};
