import { useQuery } from '@tanstack/react-query';
import { addMonths, format } from 'date-fns';

import { useSettings } from '../../settingsContext/settingsContext';
import { TrafficLights } from '../../productContext/product';
import { Timeslots, TimeslotsRange } from '../../timeslotsContext/timeslots';
import { CartState } from '../cart/cart';
import { queryKeys } from '../../../queryKeys';

import { TimeslotResponse } from './dtos/response';
import { getRange, mapToTimeslotRequest } from './mapToRequest';
import { mapToTimeslot } from './mapToTimeslot';
import { timeslotApi } from './tiimeslotApi';

interface TimeslotWithRange {
  response: TimeslotResponse;
  range: TimeslotsRange;
}

export interface TimeslotApiProps {
  initialData?: Timeslots;
  productId: string;
  numberOfMonths?: number;
  trafficLights?: TrafficLights;
  dateFrom?: Date;
  enabled?: boolean;
  /** Request months until find the next available day
   * @remarks It does not replace the numberOfMonths, that still sets the number of months
   * to be requested until next try to find an available day
   */
  nextAvailableDay?: boolean;
  showSoldOut?: boolean;
  showUpsells?: boolean;
  cart?: CartState;
}

export const useTimeslotApi = ({
  initialData,
  productId,
  numberOfMonths = 2,
  dateFrom,
  enabled,
  nextAvailableDay = false,
  showSoldOut = false,
  showUpsells = false,
  cart,
}: TimeslotApiProps) => {
  const { locale, customerCode } = useSettings();
  const initialDate = dateFrom || new Date();
  const queryDeps = [
    queryKeys.TIMESLOTS,
    locale,
    customerCode,
    productId,
    numberOfMonths,
    dateFrom?.valueOf(),
    cart?.totals.grand.gross.amount,
  ];

  const queryFn = async () => {
    // TODO: Remove this recursive function when API provides a way to get the next available day
    const requestTimeSlot = async (
      count: number = 0,
      range: TimeslotsRange = { from: initialDate, to: initialDate }
    ): Promise<TimeslotWithRange> => {
      if (count === LIMIT_OF_MONTHS) {
        return {
          response: ({
            data: [{ productId, availabilityDates: [] }],
          } as unknown) as TimeslotResponse,
          range: { from: initialDate, to: addMonths(initialDate, numberOfMonths) },
        };
      }

      const rangeToRequest = getRange(
        format(range.to, 'yyyy-MM-dd') || dateFrom || new Date(),
        numberOfMonths
      );

      const response = await timeslotApi.getDaysWithTimeslots(
        customerCode,
        mapToTimeslotRequest({
          productId,
          range: rangeToRequest,
          showSoldOut,
          showUpsells,
          cart,
        })
      );

      if (nextAvailableDay && !response.data[0]?.availabilityDates.length) {
        return requestTimeSlot(count + 1, rangeToRequest);
      }

      return { response, range: rangeToRequest };
    };

    const { response, range } = await requestTimeSlot();

    return mapToTimeslot(response, range);
  };

  return useQuery({
    queryKey: queryDeps,
    queryFn,
    placeholderData: initialData,
    enabled: !!enabled,
    keepPreviousData: true,
  });
};

const LIMIT_OF_MONTHS = 12;
